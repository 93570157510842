<template>
  <nav class="viewcontentnav">
    <div class="logo" :style="`background-image: url(${defineLogo()})`">
      <router-link to="/" class="title"></router-link>
    </div>
    <div class="mainNav" v-if="!setMenuType">
      <div class="centermenu">
        <router-link class="a_color" to="/">{{
          translations.nav_home
        }}</router-link>
        <router-link class="a_color" to="/exhibitors" v-if="!privateplatform">{{
          translations.nav_exhibitors_products
        }}</router-link>
        <router-link class="a_color" to="/products" v-else>{{
          translations.nav_products
        }}</router-link>
        <router-link
          class="a_color"
          to="/presentations"
          v-if="isDisabled('trend')"
          >{{ translations.nav_presentations }}
        </router-link>
        <router-link
          class="a_color"
          to="/investment"
          v-if="isDisabled('opportunity')"
          >{{ translations.nav_opportunities }}</router-link
        >
        <router-link class="a_color" to="/news" v-if="isDisabled('news')">{{
          translations.nav_news
        }}</router-link>

        <router-link
          class="a_color"
          :to="'/call/' + callId"
          v-if="call && logged"
          >{{ translations.nav_call }}</router-link
        >
        <Flags class="float" />
      </div>
    </div>
    <div class="burgger" @click="show()" v-if="setMenuType">
      <i class="fas fa-bars fa-2x"></i>
    </div>
    <div>
      <div v-if="setMenuType && showNav" class="mobilenav">
        <router-link class="a_color" to="/">{{
          translations.nav_home
        }}</router-link>
        <router-link class="a_color" to="/exhibitors" v-if="!privateplatform">{{
          translations.nav_exhibitors_products
        }}</router-link
        ><router-link class="a_color" to="/products" v-else>{{
          translations.nav_products
        }}</router-link>
        <router-link
          class="a_color"
          to="/presentations"
          v-if="isDisabled('trend')"
          >{{ translations.nav_presentations }}</router-link
        >
        <router-link
          class="a_color"
          to="/investment"
          v-if="isDisabled('opportunity')"
          >{{ translations.nav_opportunities }}</router-link
        >
        <router-link class="a_color" to="/news" v-if="isDisabled('news')">{{
          translations.nav_news
        }}</router-link>
        <router-link
          class="a_color"
          :to="'/call/' + callId"
          v-if="call && logged"
          >{{ translations.nav_call }}</router-link
        >
        <div class="flagscont">
          <Flags />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import Flags from '@/components/Flags.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'Navbar',
    components: {
      Flags,
    },
    data() {
      return {
        showNav: false,
        window: {
          width: 0,
        },
      }
    },

    computed: {
      ...mapState([
        'call',
        'logged',
        'callId',
        'logo',
        'files',
        'disabled_sections',
        'translations',
        'privateplatform',
      ]),
      setMenuType() {
        return this.window.width <= 1100
      },
    },
    methods: {
      show() {
        this.showNav = !this.showNav
      },
      handleResize() {
        this.window.width = window.innerWidth
      },
      defineLogo() {
        let logo = require('@/assets/logo.png')
        if (this.logo) {
          logo = this.files + 'property/' + this.logo
        }
        return logo
      },
      isDisabled(key) {
        if (this.disabled_sections) {
          return !this.disabled_sections.includes(key)
        }
      },
    },
    created() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    unmounted() {
      window.removeEventListener('resize', this.handleResize)
    },
  }
</script>

<style scoped>
  .viewcontentnav {
    display: block;
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .logo {
    text-indent: 52px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: 255px;
    font-size: 35px;
    width: 255px;
    display: block;
  }
  .logo,
  .mainNav {
    padding-top: 10px;
    position: relative;
    height: 55px;
    display: inline-block;
    vertical-align: middle;
  }

  #nav .title,
  .centermenu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .centermenu {
    top: 40%;
  }
  .right {
    vertical-align: bottom;
    margin-right: 0px;
    padding-right: 0px;
    text-align: right;
  }
  #nav {
    padding: 30px;
  }

  .mainNav {
    line-height: 2;
    text-overflow: ellipsis;
    width: 72%;
    margin-top: -10px;
    margin-bottom: 0px;
    text-indent: 5px;
    font-size: 10pt;
  }
  #nav .mainNav a,
  .logo a {
    padding: 11px 10px;
  }
  #nav .mainNav a:first-of-type {
    /* margin-left: 50px; */
  }

  #nav .logo a.router-link-exact-active {
    background-color: transparent;
  }
  .burgger {
    padding: 15px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 0;
  }
  .mobilenav a {
    display: block;
    padding: 10px;
  }
  .mobilenav .flagscont {
    display: block;
    text-align: right;
  }
  .float {
    float: right;
  }
</style>
