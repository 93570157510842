<template>
  <div
    class="itemessage retail_bg_color main_color"
    :class="defineTypeClass"
    @click="linkTo()"
    v-if="data.show"
  >
    <h2>{{ data.title }}</h2>
    <p>{{ description }}</p>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    props: {
      data: {
        type: Object,
        default: {
          id: '',
          title: '',
          description: '',
          startTime: '',
          endTime: '',
          type: '',
        },
      },
    },

    computed: {
      ...mapState(['translations']),
      description() {
        let result = ''
        let now = new Date()

        switch (this.data.type) {
          case 'call':
            if (
              now >= this.data.startTime &&
              now <= this.data.endTime &&
              typeof this.data.description == 'string'
            ) {
              result = this.replaceTranslation({
                type: 'continue',
                text: this.data.description,
              })
            } else if (now <= this.data.startTime) {
              if (this.data.description && this.data.description.length == 2) {
                result = this.replaceTranslation({
                  type: 'starthm',
                  text: [this.data.description[0], this.data.description[1]],
                })
              } else {
                result = this.replaceTranslation({
                  type: 'startm',
                  text: this.data.description[0],
                })
              }
            }

            break
          case 'chat':
            if (isNaN(this.data.description[1])) {
              result = this.replaceTranslation({
                type: 'onemessage',
                text: [this.data.description[0], this.data.description[1]],
              })
            } else {
              result = this.replaceTranslation({
                type: 'message',
                text: [this.data.description[0], this.data.description[1]],
              })
            }

            break
        }
        // Notification.requestPermission().then(function(result) {
        //   console.log(result)
        // })
        return result
      },
      defineTypeClass() {
        let bg = 'retail_bg_color'
        switch (this.data.type) {
          case 'call':
            bg = 'calendar_bg_today'

            break
          case 'chat':
            bg = 'retail_bg_color'

            break
        }
        return bg
      },
    },
    methods: {
      ...mapActions(['changeToolSelected']),
      linkTo() {
        switch (this.data.type) {
          case 'call':
            router.push('/call/' + this.data.id)
            this.changeToolSelected(null)

            break
          case 'chat':
            this.changeToolSelected(12)
            break
        }
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'onemessage':
            result = this.translations.warning_onenew_message
            result = result.replace('{var0}', value.text[0])
            result = result.replace('{var1}', value.text[1])
            break
          case 'message':
            result = this.translations.warning_new_message
            result = result.replace('{var0}', value.text[0])
            result = result.replace('{var1}', value.text[1])
            break
          case 'continue':
            result = this.translations.warning_call_continue.replace(
              '{var}',
              value.text
            )
            break
          case 'starthm':
            result = this.translations.warning_callstart_hm
            result = result.replace('{var0}', value.text[0])
            result = result.replace('{var1}', value.text[1])
            break
          case 'startm':
            result = this.translations.warning_callstart_m.replace(
              '{var}',
              value.text
            )
            break
        }

        return result
      },
    },
  }
</script>

<style scoped>
  .itemessage {
    padding: 10px;
    box-shadow: 0 5px 5px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .itemessage h2 {
    margin: 0;
    font-weight: bold;
  }
</style>
