<template>
  <div class="section eighth_bg_color">
    <div class="overflow">
      <div class="contrast_color sixth_bg_color titlecont">
        <div class="titlecenter">
          <h4>
            {{ data.fullName }}
          </h4>
          <span class="icon"><i :class="defineIcon + ' fa-2x'"></i></span>
        </div>
      </div>
    </div>
    <div class="text">
      <p class="fourth_color">{{ data.description }}</p>
      <a
        v-if="data.url"
        :href="data.url"
        class="primary_bg_color contrast_color"
        target="_blank"
        >{{ translations.btn_readmore }}</a
      >
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Section',
    props: {
      data: {
        type: Object,
        default: {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapState(['translations']),
      defineIcon() {
        let clas = ''
        switch (this.index) {
          case 0:
            clas = 'fas fa-desktop'
            break
          case 1:
            clas = 'fas fa-shopping-cart'
            break
          case 2:
            clas = 'fas fa-warehouse'
            break
          case 3:
            clas = 'fas fa-project-diagram'
            break

          default:
            break
        }
        return clas
      },
    },
  }
</script>

<style scoped>
  .section {
    margin: 10px;
  }
  .section .overflow {
    height: 90px;
    overflow: hidden;
  }
  .section .titlecont {
    display: table;
    height: 100%;
    width: 100%;
  }
  .section .titlecenter {
    display: table-row;
  }
  .section h4 {
    padding: 0 5px;
    font-size: 20px;
    text-align: left;
    margin: 0;
    font-style: normal;
    line-height: normal;
    height: auto;
    max-height: 55px;
    overflow: hidden;
    width: 80%;
  }
  .section h4,
  .section .icon {
    display: table-cell;
    vertical-align: middle;
  }
  /* .section h4 {
    margin: auto;
    width: 80%;
    height: 40px;
  } */
  .icon {
    width: 20%;
  }
  /* .section h4, */
  /* .section .icon {
    display: inline-block;
    vertical-align: top;
  } */

  /*.section .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show 
    -webkit-box-orient: vertical;
    font-size: 20px;
    padding: 20px;
    text-align: left;
    height: 40px;
  }
  */
  .text {
    height: 200px;
  }
  .text p {
    height: 150px;
    padding: 5px 10px 0px 10px;
    text-align: left;
    overflow-y: scroll;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    text-overflow: scroll;
    /* display: -webkit-box;
    -webkit-line-clamp: 7; /* number of lines to show */
    /* -webkit-box-orient: vertical; */
  }
  .text p::-webkit-scrollbar {
    display: none;
  }
  a {
    margin: 10px;
    height: auto;
    font-size: 16px;
    display: block;
  }
</style>
