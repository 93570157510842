<template>
  <div class="registcont">
    <form
      @submit.prevent="processRegist()"
      @keyup.enter="processRegist()"
      class="regist toolform"
      :class="checkPreview()"
    >
      <p class="descriptionform fourth_bg_color_transparent contrast_color">
        {{ translations.warning_needberegist }}
      </p>
      <div class="userinfo ">
        <h2>
          {{ translations.form_title_user_info }}
        </h2>
        <div class="inputcont" :class="checkData('fullname')">
          <input
            class="border_category tertiary_color"
            type="text"
            v-model="data.fullName"
            :placeholder="translations.form_ph_fullname"
          />
          <button
            type="button"
            @mouseover="hoverWarning('fullname')"
            @mouseleave="leaveWarning('fullname')"
          >
            <i class="fas fa-exclamation-triangle warning_color"></i>
          </button>
        </div>
        <div class="warningcont" v-if="warning.fullName">
          <p>
            {{ translations.warning_fullname }}
          </p>
        </div>

        <div class="inputcont" :class="checkData('email')">
          <input
            class="border_category tertiary_color"
            type="email"
            v-model="data.email"
            :placeholder="translations.form_ph_email"
          />
          <button
            type="button"
            @mouseover="hoverWarning('email')"
            @mouseleave="leaveWarning('email')"
          >
            <i class="fas fa-exclamation-triangle warning_color"></i>
          </button>
        </div>
        <div class="warningcont" v-if="warning.email">
          <p>
            {{ translations.warning_form_email }}
          </p>
        </div>

        <div class="inputcont" :class="checkData('password')">
          <input
            class="border_category tertiary_color"
            type="password"
            autocomplete="on"
            v-model="data.password"
            :placeholder="translations.form_ph_password"
          />
          <button
            type="button"
            @mouseover="hoverWarning('password')"
            @mouseleave="leaveWarning('password')"
          >
            <i class="fas fa-exclamation-triangle warning_color"></i>
          </button>
        </div>
        <div class="warningcont" v-if="warning.password">
          <p>
            {{ translations.warning_password }}
          </p>
        </div>

        <div class="inputcont" :class="checkData('repassword')">
          <input
            class="border_category tertiary_color"
            type="password"
            autocomplete="on"
            v-model="data.secPassword"
            :placeholder="translations.form_ph_retype_password"
          />
          <button
            type="button"
            @mouseover="hoverWarning('repassword')"
            @mouseleave="leaveWarning('repassword')"
          >
            <i class="fas fa-exclamation-triangle warning_color"></i>
          </button>
        </div>
        <div class="warningcont" v-if="warning.repassword">
          <p>
            {{ translations.warning_retype_password }}
          </p>
        </div>
        <div>
          <select
            class="tertiary_color border_category"
            name="regusertype"
            v-model="data.timeZone"
          >
            <optgroup style="max-height: 65px;">
              <option
                class="main_color contrast_bg_color"
                :value="null"
                disabled
                >{{ translations.form_ph_timezone }}</option
              >
              <option
                class="main_color contrast_bg_color"
                v-for="(zone, index) in timezone"
                :key="index"
                :value="zone.value"
              >
                {{ zone.name }}
              </option>
            </optgroup>
          </select>
        </div>
        <div class="inputcont">
          <SelectMultiple
            v-model="data.languages"
            :title="translations.form_ph_languages"
            :values="languages"
            class="selector"
          />
        </div>
      </div>
      <div class="businessinfo  border_category">
        <h2>
          {{ translations.form_title_business_info }}
        </h2>
        <div>
          <select
            class="tertiary_color border_category"
            name="regusertype"
            v-model="data.option"
          >
            <option class="main_color " :value="null" disabled>{{
              translations.form_ph_user_profile
            }}</option>
            <option
              class="tertiary_color "
              v-for="option in options"
              :key="option.id"
              :value="option.id"
            >
              {{ option.value }}
            </option>
          </select>
        </div>
        <div v-if="checkOption() == 0 || checkOption() == 1">
          <div>
            <input
              class="tertiary_color border_category"
              type="text"
              v-model="data.business"
              :required="checkOption() == 0"
              :placeholder="translations.form_ph_business"
            />
          </div>
        </div>
        <div v-if="checkOption() == 1">
          <div>
            <input
              class="tertiary_color border_category"
              type="text"
              v-model="data.position"
              :placeholder="translations.form_ph_business_position"
            />
          </div>
        </div>

        <div
          class="message error_color"
          v-if="errorsform.length > 0 || errors.regist.length > 0"
        >
          <div v-if="errorsform.length > 0" s>
            <p v-for="(error, index) in errorsform" :key="index">
              <span>{{ index + 1 + ': ' + error }}</span>
            </p>
          </div>
          <div v-if="errors.regist.length > 0">
            <p v-for="(error, index) in errors.regist" :key="index">
              <span>{{ index + 1 + ': ' + error }}</span>
            </p>
          </div>
        </div>
        <div class="buttonsform">
          <div class="checkbox">
            <input
              name="regaccepttou"
              class="contrast_color"
              type="checkbox"
              v-model="data.checked"
            />
            <label for="regaccepttou" class="fourth_color">
              <a
                class="terms primary_color"
                :href="defineTerms"
                target="_blank"
              >
                {{ translations.label_terms_accept }}
              </a>
            </label>
          </div>
          <button
            class="btn fourth_bg_color contrast_color"
            type="button"
            @click="removeSelected()"
          >
            {{ translations.btn_cancel }}
          </button>

          <input
            type="submit"
            class="btn primary_bg_color contrast_color"
            :value="translations.btn_register"
          />
        </div>
        <div>
          <div class="inputfile border_category tertiary_color">
            <label>
              <input
                type="file"
                class="file contrast_color border_category"
                @change="onLogoChange"
              />
              {{ translations.label_user_picture }}
            </label>
          </div>
        </div>
        <div v-if="preview">
          <div class="preview">
            <img :src="preview" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import md5 from 'js-md5'
  import SelectMultiple from '@/components/SelectMultiple.vue'

  export default {
    components: {
      SelectMultiple,
    },

    data() {
      return {
        warning: {
          fullName: false,
          email: false,
          password: false,
          repassword: false,
        },
        data: {
          fullName: null,
          email: null,
          password: null,
          secPassword: null,
          timeZone: null,
          languages: [],
          checked: false,
          option: null,
          business: '',
          position: '',
          logo: null,
        },
        preview: '',
        options: [],
        languages: [
          { value: 'en', image: 'en.svg', text: 'English' },
          { value: 'zh', image: 'cn.svg', text: '中文' },
          { value: 'es', image: 'es.svg', text: 'Español' },
          { value: 'it', image: 'it.svg', text: 'Italiano' },
          { value: 'de', image: 'de.svg', text: 'Deutsch' },
          { value: 'fr', image: 'fr.svg', text: 'Français' },
        ],

        timezone: [],
        errorsform: [],
      }
    },
    computed: {
      ...mapState(['errors', 'files', 'languageSelected', 'translations']),
      defineTerms() {
        return (
          this.files +
          'tou_' +
          this.languageSelected.toUpperCase().trim() +
          '.pdf'
        )
      },
    },
    methods: {
      ...mapActions(['changeToolSelected', 'regist', 'resetError']),

      hoverWarning(type) {
        switch (type) {
          case 'fullname':
            this.warning.fullName = true
            break
          case 'email':
            this.warning.email = true
            break
          case 'password':
            this.warning.password = true
            break
          case 'repassword':
            this.warning.repassword = true
            break

          default:
            break
        }
      },
      leaveWarning(type) {
        switch (type) {
          case 'fullname':
            this.warning.fullName = false
            break
          case 'email':
            this.warning.email = false
            break
          case 'password':
            this.warning.password = false
            break
          case 'repassword':
            this.warning.repassword = false
            break

          default:
            break
        }
      },
      removeSelected() {
        this.changeToolSelected(null)
      },

      processRegist() {
        this.errorsform = []
        const reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const rePass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!-_#/%*?&])[A-Za-z\d@$-_/#!%*?&]{10,}$/
        if (
          !this.data.fullName ||
          (this.data.fullName && this.data.fullName.length < 0)
        ) {
          this.errorsform.push(this.translations.error_form_full_name)
        }
        if (!reEmail.test(this.data.email)) {
          this.errorsform.push(this.translations.error_form_email)
          this.checkData('email')
        }
        // if (!rePass.test(this.data.password)) {
        if (
          !this.data.password ||
          (this.data.password && this.data.password.length < 10)
        ) {
          this.errorsform.push(this.translations.error_password)
        }
        if (this.data.password !== this.data.secPassword) {
          this.errorsform.push(this.translations.error_retype_password)
        }
        if (!this.data.checked) {
          this.errorsform.push(this.translations.error_form_terms)
        }
        if (this.data.languages.length == 0) {
          this.errorsform.push(this.translations.error_form_languages)
        }
        if (this.errorsform.length === 0) {
          let dataFormated = {
            fullname: this.data.fullName,
            email: this.data.email,
            password: this.encrypt(this.data.password),
            secPassword: this.encrypt(this.data.secPassword),
            option: this.data.option,
            business: this.data.business,
            position: this.data.position,
            picture: this.data.logo,
            timeOffset: this.data.timeZone,
            languages: JSON.stringify(Object.values(this.data.languages)),
          }
          this.regist(dataFormated)
        }
      },
      encrypt(pwd) {
        pwd = md5(pwd)
        return pwd
      },
      checkPreview() {
        let clas = ''
        if (this.preview) {
          clas = 'previewspace'
        }
        return clas
      },
      checkData(value) {
        let clas = ''
        let validate = null
        switch (value) {
          case 'fullname':
            if (this.data.fullName !== null) {
              validate = this.data.fullName.length > 0
            }
            break
          case 'email':
            const reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (this.data.email !== null) {
              validate = reEmail.test(this.data.email)
            }
            break
          case 'password':
            if (this.data.password !== null) {
              validate = this.data.password.length > 10
            }
            break
          case 'repassword':
            if (this.data.secPassword !== null) {
              validate =
                this.data.secPassword.length > 10 &&
                this.data.secPassword == this.data.password
            }
            break
          default:
            break
        }
        if (validate !== null && validate) {
          clas = 'valid'
        } else if (validate !== null && !validate) {
          clas = 'invalid'
        }

        return clas
      },
      onLogoChange(e) {
        const file = e.target.files[0]
        this.data.logo = file
        this.preview = URL.createObjectURL(file)
      },
      checkOption() {
        let result = null
        if (this.data.option == 1 || this.data.option == 2) {
          result = 1
        } else if (this.data.option == 3) {
          result = 0
        }
        return result
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'other':
            result = this.translations.title_other_products
            result = result.replace('{var0}', value.text[0])
            result = result.replace('{var1}', value.text[1])
            break
          case 'showmore':
            result = this.translations.btn_showmore_numeric.replace(
              '{var}',
              value.text
            )
            break
          case 'categories':
            result = this.translations.btn_showmore_numeric_categories.replace(
              '{var}',
              value.text
            )
            break
          case 'similar':
            result = this.translations.title_brands_similar_product.replace(
              '{var}',
              value.text
            )
            break
        }

        return result
      },
      addTranslations() {
        if (this.translations) {
          this.timezone = [
            { name: this.translations.time_zone_n_12, value: -12 },
            { name: this.translations.time_zone_n_11, value: -11 },
            { name: this.translations.time_zone_n_10, value: -10 },
            // { name: '(GMT -9:30) Taiohae', value: -9.5 },
            { name: this.translations.time_zone_n_09, value: -9 },
            { name: this.translations.time_zone_n_08, value: -8 },
            { name: this.translations.time_zone_n_07, value: -7 },
            {
              name: this.translations.time_zone_n_06,
              value: -6,
            },
            {
              name: this.translations.time_zone_n_05,
              value: -5,
            },
            // { name: '(GMT -4:30) Caracas', value: -4.5 },
            {
              name: this.translations.time_zone_n_04,
              value: -4,
            },
            // { name: '(GMT -3:30) Newfoundland', value: -3.5 },
            { name: this.translations.time_zone_n_03, value: -3 },
            { name: this.translations.time_zone_n_02, value: -2 },
            { name: this.translations.time_zone_n_01, value: -1 },
            {
              name: this.translations.time_zone_00,
              value: 0,
            },
            { name: this.translations.time_zone_01, value: 1 },
            { name: this.translations.time_zone_02, value: 2 },
            { name: this.translations.time_zone_03, value: 3 },
            // { name: '(GMT +3:30) Tehran', value: 3.5 },
            { name: this.translations.time_zone_04, value: 4 },
            // { name: '(GMT +4:30) Kabul', value: 4.5 },
            { name: this.translations.time_zone_05, value: 5 },
            // {
            //   name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
            //   value: 5.5,
            // },
            // { name: '(GMT +5:45) Kathmandu, Pokhara', value: 5.75 },
            { name: this.translations.time_zone_06, value: 6 },
            // { name: '(GMT +6:30) Yangon, Mandalay', value: 6.5 },
            { name: this.translations.time_zone_07, value: 7 },
            { name: this.translations.time_zone_08, value: 8 },
            // { name: '(GMT +8:45) Eucla', value: 8.75 },
            { name: this.translations.time_zone_09, value: 9 },
            // { name: '(GMT +9:30) Adelaide, Darwin', value: 9.5 },
            { name: this.translations.time_zone_10, value: 10 },
            // { name: '(GMT +10:30) Lord Howe Island', value: 10.5 },
            { name: this.translations.time_zone_11, value: 11 },
            // { name: '(GMT +11:30) Norfolk Island', value: 11.5 },
            { name: this.translations.time_zone_12, value: 12 },
            // { name: '(GMT +12:45) Chatham Islands', value: 12.75 },
            { name: this.translations.time_zone_13, value: 13 },
            { name: this.translations.time_zone_14, value: 14 },
          ]
          this.options = [
            { id: 5, value: this.translations.type_user_consumer },
            { id: 2, value: this.translations.type_user_business },
            { id: 3, value: this.translations.type_user_media },
            { id: 1, value: this.translations.type_user_professional },
            { id: 6, value: this.translations.type_user_other_professional },
            // { id: 4, value: 'usr_profile_translator' },
          ]
        }
      },
    },

    created() {
      this.resetError({ regist: [] })
      this.addTranslations()
    },
  }
</script>

<style scoped>
  /* .checkbox {
    background-color: #a7a7a782;
  } */
  .registcont {
    height: 100%;
  }
  .checkbox input {
    width: 16px;
    height: 16px;
    margin: auto;
    margin-right: 5px;
    /* display: inline-block;
    vertical-align: middle; */
  }
  .checkbox label {
    width: 100%;
    vertical-align: text-bottom;
  }
  .preview {
    /* border: 1px solid #303030; */
    padding: 5px;
    position: relative;
    height: 150px;
    width: 150px;
    margin: 0 auto;
  }
  .selector {
    width: 60%;
  }
  .preview img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 20px auto;
  }

  .regist {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .userinfo,
  .businessinfo {
    width: 50%;
    display: inline-block;
  }
  .businessinfo {
    border-width: 0 0 0 1px;
    border-style: solid;
    position: relative;
    height: 100%;
  }

  .descriptionform {
    position: absolute;
    z-index: 1;
  }
  .buttonsform {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backface-visibility: hidden;
  }

  h2 {
    margin-top: 40px;
  }
  .terms {
    font-weight: bold;
  }

  .inputcont {
    position: relative;
  }
  .inputcont button {
    position: absolute;
    top: 40%;
    right: 20%;
    display: none;
    height: auto;
  }
  .invalid button {
    display: block;
  }

  .warningcont {
    height: 20px;
  }
  @media screen and (max-width: 1000px) {
    .userinfo,
    .businessinfo {
      display: block;
      width: 100%;
    }
    .userinfo {
      background-color: #fff;
    }
    .registcont {
      height: 2000px;
    }

    /* .previewspace {
      overflow: hidden;
      overflow-y: scroll;
      height: 90%;
    } */
    .previewspace .businessinfo {
      height: 625px;
    }
    .regist {
      display: block;
      /* overflow-y: scroll; */
      height: 700px;
    }
    .descriptionform {
      position: relative;
    }
    /* .buttonsform {
      position: fixed;
    } */
    h2 {
      margin-top: 15px;
      padding-top: 20px;
    }
    .userinfo h2 {
      margin-top: 0;
    }
    .businessinfo {
      border-width: 0 0 0 0;

      height: 565px;
    }
    .inputcont button {
      right: 10%;
    }
  }
  @media screen and (max-width: 800px) {
    .inputcont button {
      right: 5%;
    }
  }
  @media screen and (max-width: 670px) {
    .businessinfo {
      height: 540px;
    }
    .buttonsform {
      bottom: 0;
    }
  }
</style>
