<template>
  <div class="stylecont">
    <button
      v-for="(type, index) in types"
      :key="index"
      :class="checkSelected(type.id)"
      @click="changeSelected(type.id)"
    >
      <i :class="type.icon + ' fa-2x'"></i>
    </button>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        types: [
          {
            id: 0,
            icon: 'fas fa-th',
          },
          {
            id: 1,
            icon: 'fas fa-th-list',
          },
        ],
      }
    },
    props: {
      selected: {
        type: Number,
        default: 0,
      },
    },
    emits: ['changeSelected'],

    methods: {
      changeSelected(id) {
        this.$emit('changeSelected', id)
      },
      checkSelected(id) {
        return this.selected == id ? 'primary_color' : ''
      },
    },
  }
</script>

<style scoped>
  .stylecont {
    float: right;
  }
</style>
