<template>
  <div class="trend" @click="showMore()">
    <div v-if="index % 2" class="left">
      <div
        class="imgcont contrast_bg_color main_color"
        :style="`background-image: url(${files + data.picture})`"
      >
        <!-- <img :src="files + data.picture" :alt="data.fullName"  -->

        <!-- /> -->
      </div>
      <div class="text">
        <h2 class="tertiary_color">{{ data.fullName }}</h2>
        <p class="grey_contrast_color">{{ checkText }}</p>
      </div>
    </div>
    <div v-else class="right">
      <div class="text">
        <h2 class="tertiary_color">{{ data.fullName }}</h2>
        <p class="grey_contrast_color">{{ checkText }}</p>
      </div>
      <div
        class="imgcont contrast_bg_color main_color"
        :style="`background-image: url(${files + data.picture})`"
      >
        <!-- <img :src="files + data.picture" :alt="data.fullName" /> -->
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Trend',
    props: {
      data: {
        type: Object,
        default: {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapState(['files', 'logged']),
      checkText() {
        return this.data.description.replace(/<br\s*\/?>/g, '')
      },
    },
    methods: {
      showMore() {
        router.push('/presentations/' + this.data.code)
      },
    },
  }
</script>

<style scoped>
  h2 {
    margin: 10px 0;
    font-size: 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  p {
    font-size: 14px;
  }
  .trend {
    padding: 20px 125px;
    height: 175px;
  }
  .imgcont {
    display: inline-block;
    position: relative;
    height: 150px;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .text p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .text {
    text-align: left;
    padding: 0 30px;
  }
  .text,
  .imgcont {
    width: 45%;
    display: inline-block;
    vertical-align: top;
  }
  .right .imgcont {
    box-shadow: 5px 5px 5px;
    float: right;
  }
  .right .text {
    clear: right;
  }
  .left .text {
    clear: left;
  }
  .left .imgcont {
    box-shadow: -5px 5px 5px;
    float: left;
  }

  @media screen and (max-width: 1100px) {
    .text,
    .imgcont {
      width: 43%;
    }
    .trend {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 650px) {
    h2 {
      font-size: 19px;
    }
    .text {
      padding: 0;
    }
    .text p {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .text,
    .imgcont {
      display: block;
      width: 100%;
    }
    .right .imgcont,
    .left .imgcont {
      float: none;
      box-shadow: none;
      box-shadow: 0 5px 5px;
    }
    .left .text,
    .right .text {
      clear: none;
    }
    .imgcont {
      padding: 0;
      position: absolute;
      bottom: 0;
      width: 90%;
      right: 0;
      margin: auto;
      left: 0;
    }
    .trend {
      height: 265px;
      margin-bottom: 20px;
      position: relative;
    }
  }
  @media screen and (max-width: 650px) {
    h2 {
      font-size: 18px;
    }
  }
</style>
