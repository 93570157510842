<template>
  <div class="chatcont">
    <div v-if="!errors.chat && fileredList.length > 0" class="chat">
      <div class="menu-chat">
        <button
          type="button"
          @click="displayContactsFullScreen()"
          :disabled="disabled"
        >
          {{ translations.btn_contacts }}
        </button>
      </div>
      <div id="menu-chat" class="contacts">
        <div class="search ">
          <input
            type="text"
            :placeholder="translations.form_ph_search"
            v-model="search"
          />
          <i class="fas fa-search"></i>
        </div>
        <div class="mycontacts">
          <ChatContact
            v-for="(contact, index) in fileredList"
            :key="index"
            :contact="contact"
            :selected="chatSelected"
            @click="getContact(contact)"
            :class="checkSelected(contact)"
          />
        </div>
      </div>

      <div id="message-platform" class="actualchat" ref="actualchat">
        <div v-if="chatSelected" class="blockmessage">
          <ChatMessage
            v-for="(message, index) in chat.messages"
            :key="index"
            :message="message"
          />
        </div>
        <div class="msgchat info_color" v-else>
          {{ translations.label_chat_select }}
        </div>

        <div class="sendmessage">
          <div class="pdflist nineth_bg_color" v-if="downloads">
            <button
              v-for="(file, index) in filesstorage"
              :key="index"
              class="primary_color border_category download"
              @click="prepareMessage({ type: 2, file: file })"
            >
              {{ file.full_name }}
            </button>
          </div>
          <div class="contrast_bg_color" v-if="chatSelected">
            <div class="send">
              <button
                type="button"
                @click="showDownloads()"
                :disabled="disabled"
                v-if="checkUser"
              >
                <i class="fas fa-paperclip"></i>
              </button>
              <div v-else></div>
              <input
                type="text"
                class="inputchat"
                v-model="message"
                :placeholder="translations.form_ph_message"
                @keyup.enter="prepareMessage({ type: 1 })"
              />
              <button
                type="button"
                @click="prepareMessage({ type: 1 })"
                :disabled="disabled"
              >
                <i class="fas fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="info_color info" v-else>
      <i class="fas fa-comment-slash fa-2x"></i>
      <p v-if="errors.chat">
        {{ translations.warning_chat }}
      </p>
      <p v-else-if="fileredList.length == 0">
        {{ translations.warning_chat_no_contacts }}
      </p>
    </div>
    <div class="buttonsform contrast_bg_color">
      <button
        @click="removeSelected()"
        class="btntool fourth_bg_color contrast_color"
      >
        {{ translations.btn_close }}
      </button>
    </div>
  </div>
</template>

<script>
  import ChatMessage from '@/components/ChatMessage.vue'
  import ChatContact from '@/components/ChatContact.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    data() {
      return {
        search: '',
        token: '',
        chatSelected: null,
        message: '',
        tiemr: null,
        downloads: false,
        disabled: false,
        contacts_fullscreen: false,
      }
    },
    components: {
      ChatMessage,
      ChatContact,
    },
    computed: {
      ...mapState([
        'chat',
        'refreshchat',
        'selectedTool',
        'errors',
        'clickCard',
        'files',
        'translations',
      ]),
      checkUser() {
        let ls = JSON.parse(localStorage.getItem('loged'))
        let validUsers = [0, 1, 2]
        return validUsers.includes(ls.option)
      },
      fileredList() {
        if (this.chat.contacts) {
          return this.chat.contacts
            .filter((contact) => {
              return contact.fullName
                .toLowerCase()
                .includes(this.search.toLowerCase())
            })
            .sort(function(a, b) {
              return a.position - b.position
            })
        }
      },
      filesstorage() {
        let ls = JSON.parse(localStorage.getItem('loged'))
        let result = []
        ls.downloads.forEach((element) => {
          if (element.url) {
            result.push(element)
          }
        })
        return result
      },
    },
    methods: {
      ...mapActions([
        'sendMessage',
        'getContacts',
        'getChat',
        'checkMessages',
        'changeToolSelected',
      ]),
      removeSelected() {
        this.changeToolSelected(null)
      },
      displayContactsFullScreen() {
        this.contacts_fullscreen = !this.contacts_fullscreen
        var element = document.getElementById('menu-chat')
        var chat = document.getElementById('message-platform')
        if (this.contacts_fullscreen) {
          chat.classList.add('hide')
          element.classList.remove('hide')
          element.classList.add('display')
        } else {
          chat.classList.remove('hide')
          element.classList.add('hide')
          element.classList.remove('display')
        }
      },
      scrollToEnd() {
        let container = this.$refs.actualchat
        container.scrollTop = container.scrollHeight
      },
      async getContact(value) {
        //hide contacts and display only the chat
        this.contacts_fullscreen = false

        if (value) {
          this.chatSelected = value.tchat
          await this.getChat(value)

          var element = document.getElementById('menu-chat')
          element.classList.add('hide')
          element.classList.remove('display')
          var chat = document.getElementById('message-platform')
          chat.classList.remove('hide')
          this.scrollToEnd()

          if (
            localStorage.getItem('chat') &&
            localStorage.getItem('chatback')
          ) {
            let chatlistback = JSON.parse(localStorage.getItem('chatback'))
            let chatlist = JSON.parse(localStorage.getItem('chat'))

            // objIndex = chatlist.some((obj) => obj.code == value.code)

            let nummessages = null
            for (var i in chatlistback.chats) {
              if (chatlistback.chats[i].code == value.code) {
                nummessages = chatlistback.chats[i].n_message
                break //Stop this loop, we found it!
              }
            }
            for (var i in chatlist.chats) {
              if (chatlist.chats[i].code == value.code) {
                chatlist.chats[i].n_message = nummessages
                break //Stop this loop, we found it!
              }
            }

            localStorage.setItem('chat', JSON.stringify(chatlist))
            this.checkMessages()
          }
        }
      },
      async prepareMessage(value) {
        let message = null
        switch (value.type) {
          case 1:
            if (this.message.length != 0) {
              this.disabled = true

              message = this.message
            }
            break
          case 2:
            if (value.file) {
              message = JSON.stringify(value.file)
            }
            break
        }
        let data = {
          type: value.type,
          message: message,
          token: this.chatSelected,
        }
        this.timeout = setTimeout(() => {
          this.disabled = false
        }, 2000)
        await this.sendMessage(data)

        if (value.type == 1) {
          this.message = ''
        }
        this.scrollToEnd()
      },
      showDownloads() {
        this.downloads = !this.downloads
      },
      async downloadFile() {
        // console.log(this.chatSelected)
        // this.downloadChat(this.chatSelected)
        // this.forceFileDownload('hola')
        await this.getContacts()
        // console.log(this.chat.contacts)
        let user = this.chat.contacts.filter((element) => {
          // console.log(element.code)
          // console.log(this.chatSelected)
          return element.tchat == this.chatSelected
        })

        let fileHTML =
          '<html><head><title>Chat logs</title><style>.bg-green {background-color: #64ff9121;} .img { height: 50px; width: 50px; border-radius: 100%; background-repeat: no-repeat; background-position: top; background-size: cover; margin: 0 auto; }</style> <meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"><link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"></head><body><div class="container my-2">'
        console.log(this.chat.messages)
        fileHTML +=
          '<div class="col-6 d-inline-block my-3"><div class="chatimg d-inline-block align-middle"><div class="img contrast_bg_color" style="background-image: url(' +
          this.files +
          user[0].picture +
          ')"></div></div><div class="chatname d-inline-block mx-3"><span class="name">' +
          user[0].fullName +
          '</span><span class="text-muted mx-2">' +
          user[0].business +
          '</span></div></div>'

        this.chat.messages.forEach((element) => {
          if (element.hasOwnProperty('mymessage')) {
            fileHTML += '<div class="row my-1 justify-content-end">'
            fileHTML +=
              '<div class="col-9 text-right border border-success rounded p-0">'
          } else {
            fileHTML += '<div class="row my-1">'
            fileHTML +=
              '<div class="col-9 text-left border border-success rounded p-0">'
          }

          fileHTML += '<h6 class="m-0 p-2">'
          fileHTML += '<span>' + element.date + '</span>'
          fileHTML += '</h6>'
          fileHTML += '<p class="bg-green m-0 p-2">'
          if (typeof element.text === 'object' && element.text != null) {
            // console.log(element.text)
            fileHTML +=
              element.text.full_name + '<a href="' + element.text.url + '">'
            fileHTML +=
              '<i class="fas fa-cloud-download-alt text-success px-2"></i></a>'
          } else {
            // console.log()
            fileHTML += element.text
          }
          fileHTML += '</p>'

          fileHTML += '</div></div>'
        })
        fileHTML += '</div></body></html>'
        // console.log(fileHTML)
        const url = window.URL.createObjectURL(new Blob([fileHTML]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'chat.html') //or any other extension
        document.body.appendChild(link)
        link.click()
      },
      checkSelected(value) {
        return this.chatSelected == value.tchat
          ? 'primary_bg_color_transparent contrast_color'
          : ''
      },
    },

    async created() {
      await this.getContacts()
      if (!this.chatSelected && this.clickCard != '') {
        this.chatSelected = this.clickCard
      }
      // await console.log(this.chat.contacts)
      let num = 5
      if (
        this.refreshchat ||
        (this.refreshchat != 0 && this.refreshchat >= 5)
      ) {
        num = this.refreshchat
      }

      if (this.selectedTool) {
        this.timer = setInterval(() => {
          this.checkMessages()
          this.getContacts()
          if (this.chatSelected) {
            this.getChat({ tchat: this.chatSelected })
          }
        }, num * 1000)
      }
    },
    beforeUnmount() {
      clearInterval(this.timer)
      clearTimeout(this.timeout)
    },
  }
</script>
<style scoped>
  .chat {
    height: 100%;
  }
  .blockmessage {
    width: 100%;
    display: block;
  }
  .download {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 1px 1px;
    font-size: 13px;
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
  .pdflist {
    position: fixed;
    bottom: 80px;
    left: 5px;
    height: 200px;
    width: 20%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .pdflist::-webkit-scrollbar {
    display: none;
  }
  .info {
    width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
  .chatcont {
    position: absolute;
    top: 30px;
    bottom: 45px;
    left: 0;
    right: 0;
  }
  .contacts,
  .actualchat {
    height: 93%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: scroll;
    position: relative;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    scroll-snap-align: center;
  }
  .contacts {
    display: inline-block;
    width: 33%;
    border-right-width: 1px;
    border-right-style: solid;
  }
  .menu-chat {
    display: none;
    width: 100%;
  }
  .hide {
    display: inline-block;
  }
  .actualchat {
    width: 65%;
  }
  .actualchat::-webkit-scrollbar,
  .contacts::-webkit-scrollbar {
    display: none;
  }
  .sendmessage {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    border-top-width: 2px;
    border-top-style: solid;
  }
  .sendmessage input {
    width: 70%;
  }
  .contact {
    border-width: 1px 0;
    border-style: solid;
    text-align: left;
  }
  .message {
    text-align: right;
  }
  .send input,
  .search input {
    border: transparent;
    vertical-align: center;
  }
  .search {
    border-width: 1px;
    border-style: solid;
    height: 20px;
  }
  .send {
    height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .send input,
  .send button {
    padding: 0;
    vertical-align: middle;
  }
  .send input {
    height: 38px;
    width: 80%;
    border-bottom: 1px solid;
  }
  .send button {
    width: 10%;
  }
  .search {
    height: 30px;
    margin: 10px 10px 10px 0;
  }
  .search input {
    height: 30px;
    padding: 0;
    width: 85%;
    padding-right: 1px;
  }
  button {
    height: auto;
  }
  .mycontacts {
    margin-top: 30px;
  }
  .msgchat {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 1300px) {
  }
  @media screen and (max-width: 800px) {
    .contacts {
      display: none;
    }
    .menu-chat {
      display: inline-block;
    }
    .display {
      display: inline-block !important;
      width: 100%;
    }
    .actualchat {
      width: 100%;
    }
    .hide {
      display: none;
    }
  }
  @media screen and (max-width: 700px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 450px) {
  }
</style>
