<template>
  <div>
    <h1>{{ newsItem.fullName }}</h1>
    <div class="newsdata">
      <!-- <div class="imgcont"></div> -->
      <div class="text">
        <img :src="files + newsItem.picture" alt="" />
        <p v-for="(text, index) in checkText" :key="index">
          {{ text }}
        </p>
      </div>
    </div>
    <div class="buttonsform contrast_bg_color">
      <button
        @click="removeSelected()"
        class="btntool fourth_bg_color contrast_color"
      >
        {{ translations.btn_close }}
      </button>
    </div>
    <!-- {{ newsItem }} -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'ToolNews',

    computed: {
      ...mapState(['newsItem', 'files', 'translations']),
      checkText() {
        return this.newsItem.description.split(/<br\s*\/?>|\n/g)
      },
    },
    methods: {
      ...mapActions(['changeToolSelected']),
      removeSelected() {
        this.changeToolSelected(null)
      },
    },
  }
</script>

<style scoped>
  p {
    margin: 5px;
    text-align: left;
  }
  img {
    float: left;
    padding: 15px;
  }
  .text {
    margin-top: 10px;
  }
</style>
