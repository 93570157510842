<template>
  <div class="container">
    <p class="descriptionform">
      {{ translations.warning_needbelogged }}
    </p>
    <form @submit.prevent="processLogin" class="toolform">
      <div class="message error_color" v-if="error || errors.login.length > 0">
        <p v-if="errors.login">
          <span v-for="(item, index) in errors.login" :key="index">
            {{ item }}
          </span>
        </p>
      </div>
      <div
        class="message info_color"
        v-if="mesages && mesages.login.length > 0"
      >
        <div v-for="(msg, index) in mesages.login" :key="index">
          {{ msg }}
        </div>
      </div>
      <div>
        <input
          class="border_category"
          type="email"
          v-model.trim="data.email"
          @keyup.enter="processLogin"
          :placeholder="translations.form_ph_email"
        />
      </div>
      <div>
        <input
          class="border_category"
          type="password"
          autocomplete="on"
          v-model.trim="data.password"
          @keyup.enter="processLogin"
          :placeholder="translations.form_ph_password"
        />
      </div>
    </form>
    <div>
      <button
        class="btn fourth_bg_color contrast_color"
        @click="removeSelected()"
      >
        {{ translations.btn_cancel }}
      </button>
      <button
        class="contrast_color btn"
        type="submit"
        :class="checkData() ? 'primary_bg_color' : 'fifth_bg_color'"
        :disabled="!checkData()"
        @click="processLogin"
      >
        {{ translations.btn_login }}
      </button>
    </div>
    <div>
      <button class="a_color" @click="checkRecoverEmail()">
        {{ translations.btn_forget_password }}
      </button>
      <span class="separator"> {{ translations.label_separator_or }} </span>
      <button class="regist primary_color" @click="toRegist()">
        {{ translations.btn_register }}
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import md5 from 'js-md5'
  export default {
    data() {
      return {
        data: { email: '', password: '' },
        error: false,
      }
    },
    computed: {
      ...mapState(['errors', 'mesages', 'translations']),
    },
    methods: {
      ...mapActions([
        'changeToolSelected',
        'login',
        'resetError',
        'recoverPassword',
      ]),

      processLogin() {
        const reEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (this.data.email && this.data.password) {
          if (reEmail.test(this.data.email)) {
            let dataFormated = {
              email: this.data.email,
              password: this.encrypt(this.data.password),
            }
            this.login(dataFormated)
          } else {
            this.error = true
          }
        }
      },
      checkRecoverEmail() {
        if (this.data.email) {
          this.recoverPassword(this.data.email)
        } else {
          this.errors.login.push('Write your email and then try again')
        }
      },
      removeSelected() {
        this.changeToolSelected(null)
      },
      toRegist() {
        this.changeToolSelected(1)
      },
      encrypt(pwd) {
        pwd = md5(pwd)
        return pwd
      },
      checkData() {
        return this.data.email.length > 0 && this.data.password.length > 5
      },
    },

    created() {
      this.resetError({ login: [] })
    },
  }
</script>

<style scoped>
  .container {
    width: 70%;
    margin: 0 auto;
  }
  .toolform {
    overflow-y: auto;
  }

  .separator {
    margin: 0 0 0 5px;
  }
  @media screen and (max-width: 800px) {
    .container {
      width: 80%;
    }
  }
  @media screen and (max-width: 600px) {
    .container {
      width: 90%;
    }
  }
</style>
