import { createI18n } from 'vue-i18n'

let language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

const i18n = new createI18n({
    locale: language,
    messages: loadLocaleMessages()
});
function loadLocaleMessages() {
    const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

export default i18n;
