<template>
  <div class="sponsor">
    <!-- <h4 class="contrast_color primary_bg_color">{{ data.fullName }}</h4> -->
    <div class="contrast_color primary_bg_color titlecont">
      <div class="titlecenter">
        <h4>
          {{ data.fullName }}
        </h4>
      </div>
    </div>
    <div
      class="img"
      :style="{
        'background-image': `url(${files + data.picture})`,
      }"
    ></div>
    <div class="text secundary_bg_color_transparent">
      <p class="a_color">
        {{ data.description }}
      </p>
      <a
        v-if="data.url"
        :href="data.url"
        class="primary_color"
        target="_blank"
        >{{ translations.btn_readmore }}</a
      >
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Sponsors',
    props: {
      data: {
        type: Object,
        default: {
          id: '',
          description: '',
          fullName: '',
          picture: '',
          url: '',
        },
      },
    },
    computed: {
      ...mapState(['files', 'translations']),
    },
  }
</script>

<style scoped>
  /* .sponsor h4 {
    font-size: 20px;
    padding: 20px;
    margin: 0;
    text-align: left;
    height: 40px;
  } */
  .sponsor .titlecont {
    display: table;
    height: 98px;
    overflow: hidden;
    width: 100%;
  }
  .sponsor .titlecenter {
    display: table-cell;
    vertical-align: middle;
  }
  .sponsor h4 {
    font-size: 20px;
    padding: 0 5px;
    text-align: left;
    margin: 0;
    font-style: normal;
    line-height: normal;
    height: auto;
    max-height: 55px;
    overflow: hidden;
  }
  .sponsor .img {
    margin: 0;
    width: 100%;
    height: 175px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .sponsor .text {
    height: 400px;
  }
  .text p {
    padding: 10px 20px;
    text-align: left;
    height: 344px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 14; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .sponsor {
    padding: 15px;
  }
</style>
