<template>
  <div class="messagecont " v-if="message && message.text">
    <div
      class="message contrast_bg_color"
      :class="checkPosition"
      v-if="message.type == 1"
    >
      <span v-html="checkText"></span>
    </div>
    <div
      class="download contrast_bg_color"
      :class="checkPosition"
      v-else-if="message.type == 2"
    >
      <a
        :href="defineUrl"
        class="primary_color"
        target="_blank"
        v-if="message.text && message.text.full_name"
      >
        <span class="text">
          <i class="fas fa-cloud-download-alt" v-if="message.mymessage"></i>
          {{ message.text.full_name }}
          <i class="fas fa-cloud-download-alt" v-if="!message.mymessage"></i>
        </span>
      </a>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'ChatMessage',
    props: {
      message: {
        type: Object,
        default: {
          token: '',
          fullName: '',
          text: null,
          date: '',
          mymessage: false,
        },
      },
    },
    methods: {},
    computed: {
      ...mapState(['files']),
      checkText() {
        const URLMatcher = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
        let withLinks = this.message.text.replace(
          URLMatcher,
          (match) =>
            `<a href="${match}" class='` +
            this.checkPosition +
            ` link' target='_blank'>${match}</a>`
        )
        return withLinks
      },
      checkPosition() {
        return this.message.mymessage
          ? 'chatright_bg_transparent primary_color right'
          : 'chatleft_bg_transparent sixth_color left'
      },
      defineUrl() {
        let result = ''
        if (this.message.text && this.message.text.url) {
          if (this.message.text.url.search('http') == -1) {
            result += this.files
          }

          result += this.message.text.url
        }

        return result
      },
    },
  }
</script>
<style>
  .link {
    font-weight: bold;
    text-decoration: underline;
  }
</style>
<style scoped>
  .messagecont {
    width: 100%;
    display: block;
    position: relative;
    border-radius: 15px;
    text-align: center;
  }
  .messagecont .download,
  .messagecont .message {
    display: block;
    width: 50%;
    margin: 5px 20px;
    padding: 5px;
    font-weight: normal;
    text-align: center;
    border-style: solid;
    border-width: 1px;
  }
  .messagecont .download .text {
    text-decoration: underline;
  }
  .messagecont .message {
    border-radius: 15px;
    position: relative;
  }

  .messagecont .left {
    box-shadow: 0px 2px;
    float: left;
    /* left: 0; */
    border-top-left-radius: 0;
    text-align: left;
  }
  .messagecont .right {
    /* right: 0; */
    box-shadow: 0px 2px;
    float: right;
    text-align: right;
    border-top-right-radius: 0;
  }

  .messagecont .right:before,
  .messagecont .left:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-style: solid;
    border-width: 8px;
    top: -1px;
    z-index: -1;
  }
  .messagecont .right:before {
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    margin-top: 1px;
    right: -16px;
  }

  .messagecont .left:before {
    border-left-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -16px;
  }
</style>
