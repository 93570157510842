<template>
  <div class="titlecontent" :class="background + ' ' + color">
    <div class="titletextcontent" :class="align">
      <h1 v-if="type == 'title'">{{ text }}</h1>
      <h2 v-if="type == 'subtitle'">{{ text }}</h2>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: 'title',
      },
      background: {
        type: String,
        default: 'primary_bg_color',
      },
      color: {
        type: String,
        default: 'contrast_color',
      },
      align: {
        type: String,
        default: 'left',
      },
    },
  }
</script>

<style scoped>
  .dark h1,
  .dark h2 {
    margin: 0;
  }
  h1 {
    font-size: 27px;
    margin-top: 10px;
  }
  h2 {
    padding: 5px;
    font-size: 23px;
  }
  .titlecontent {
    width: 100%;
  }

  .titletextcontent {
    max-width: 1140px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .center {
    text-align: center;
  }
  @media screen and (max-width: 1100px) {
    h1 {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 18px;
    }
    h1 {
      font-size: 22px;
    }
  }
</style>
