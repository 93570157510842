<template>
  <div class="itemcont border_shadow" :class="defineStyle">
    <Banner :type="2" v-if="item.banner" :info="item.info" />
    <div v-else>
      <div v-if="logged && favorites" class="addfavorites">
        <button @click="addToMyBriefcase()" type="button" v-if="show">
          <i class="fas fa-bookmark primary_color"></i>
        </button>
        <button @click="addToMyBriefcase()" type="button" v-else>
          <i class="far fa-bookmark fifth_color"></i>
        </button>
      </div>
      <div
        v-else-if="type == 'favorite'"
        @click="deleteToMyBriefcase()"
        class="addfavorites"
      >
        <button type="button" class="trash">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
      <div @click="defineURL()" class="infocont">
        <div class="left">
          <img :src="files + item.picture" alt="" />
        </div>
        <div class="right border_shadow" :class="defineType">
          <div class="info a_color ">
            <h3 class="border_shadow">
              {{ item.title }}
            </h3>
            <div v-if="style == 1">
              <p
                v-for="(text, index) in item.description.split(
                  /<br\s*\/?>|\\n/g
                )"
                :key="index"
              >
                {{ text }}
              </p>
            </div>
          </div>
          <div class="products" v-if="item.products">
            <div
              v-for="(product, index) in item.products.slice(0, 3)"
              :key="index"
              class="imgcont"
            >
              <img :src="files + product.img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/Banner.vue'
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'ListItem',
    props: {
      item: {
        type: Object,
        default: {
          id: '1',
          title: 'Product1',
          description: 'description product 1',
          picture: 'product.png',
        },
      },
      type: {
        type: String,
        default: 'product',
      },
      style: {
        type: Number,
        default: 1,
      },
      favorites: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        show: false,
      }
    },
    components: {
      Banner,
    },
    methods: {
      ...mapActions(['changeToolSelected', 'getFavorites', 'checkMyFavorites']),
      defineURL() {
        let url = ''
        let type = this.type != 'favorite' ? this.type : this.item.type
        switch (type) {
          case 'product':
            url = '/product/' + this.item.code
            break
          case 'exhibitor':
            url = '/exhibitor/' + this.item.code
            break
          case 'opportunity':
            url = '/investment/' + this.item.code
            break
          case 'news':
            url = '/news/'
            break
          case 'trends':
            url = '/presentations/' + this.item.code
            break
        }

        router.push(url)
        this.changeToolSelected(null)
      },
      async addToMyBriefcase() {
        var ls = localStorage.getItem('myFavorites')
        ls = ls ? JSON.parse(ls) : { items: [] }
        let find = false
        let i = 0
        let index = null
        if (ls.items.length > 0) {
          ls.items.forEach((element) => {
            if (element.code == this.item.code && element.type == this.type) {
              find = true
              index = i
            }
            i++
          })
        }
        if (!find) {
          ls.items.push({ code: this.item.code, type: this.type })
          this.show = true
        } else {
          ls.items.splice(index, 1)
          this.show = false
        }
        localStorage.setItem('myFavorites', JSON.stringify(ls))
        await this.checkMyFavorites()
      },
      async deleteToMyBriefcase() {
        var ls = localStorage.getItem('myFavorites')
        ls = ls ? JSON.parse(ls) : { items: [] }
        let find = false
        let i = 0
        let index = null
        if (ls.items.length > 0) {
          ls.items.forEach((element) => {
            if (
              element.code == this.item.code &&
              element.type == this.item.type
            ) {
              find = true
              index = i
            }
            i++
          })
        }
        if (find) {
          ls.items.splice(index, 1)
          localStorage.setItem('myFavorites', JSON.stringify(ls))
          await this.checkMyFavorites()
          await this.getFavorites()
        }
      },
      isInMyBriefcase() {
        var ls = localStorage.getItem('myFavorites')
        ls = ls ? JSON.parse(ls) : { items: [] }
        let find = false

        ls.items.forEach((element) => {
          if (
            element.code == this.item.code &&
            element.type == this.item.type
          ) {
            find = true
          }
        })
        this.show = find
      },
    },
    computed: {
      ...mapState(['logged', 'files', 'myfavoritesBack']),
      defineStyle() {
        let clas = ''
        if (this.item.banner) {
          clas = 'banner'
        } else if (this.style == 0) {
          clas = 'columncont'
        } else if (this.style == 1) {
          clas = 'listcont'
        }
        return clas
      },
      defineType() {
        let clas = ''
        switch (this.type) {
          case 'product':
            clas = 'product'
            break
          case 'exhibitor':
            clas = 'exhibitor'
            break

          default:
            break
        }
        return clas
      },
    },
    created() {
      this.isInMyBriefcase()
    },
    watch: {
      'myfavoritesBack.items'(newValue, oldValue) {
        this.isInMyBriefcase()
      },
    },
  }
</script>

<style scoped>
  .banner {
    box-shadow: 0 0 !important;
    border-width: 0 !important;
    height: 155px;
  }

  h3 {
    margin: 10px 0px;
  }
  .itemcont {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 3px 3px #d4d4d4;
    margin: 10px;
  }
  .infocont {
    width: 93%;
    display: inline-block;
  }
  .listcont .left,
  .listcont .right {
    display: inline-block;
    vertical-align: middle;
  }
  .left {
    width: 10%;
    border-radius: 10px;
    text-align: center;
    margin: 10px;
    position: relative;
    height: 135px;
  }
  .products {
    text-align: center;
    vertical-align: top;
  }
  .imgcont {
    display: inline-block;
    width: 20%;
    position: relative;
    height: 120px;
    padding: 0 20px;
  }

  .left img,
  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 95px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .listcont .right {
    height: 115px;
    border-left-width: 1px;
    border-left-style: solid;
    width: 80%;
    padding: 20px;
    text-align: left;
    overflow: hidden;
  }
  .listcont .exhibitor p {
    font-size: 10px;
  }
  .info {
    height: 100px;
    overflow: hidden;
  }
  .listcont .exhibitor .products,
  .listcont .exhibitor .info {
    display: inline-block;
    width: 50%;
    height: 115px;
  }
  .trash:hover svg {
    color: #db7373d7;
  }
  .trash svg {
    color: #d25252;
    top: -1rem;
  }
  .trash {
    width: 0;
    height: 0;
    position: absolute;
    right: 20px;
    top: 0;
  }

  .columncont {
    width: 30%;
    display: inline-block;
  }
  .columncont .exhibitor h3 {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 20px 0;
  }
  .columncont .right,
  .columncont .left {
    text-align: center;
    width: 100%;
  }
  .columncont .left {
    width: 95%;
  }
  .listcont p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .listcont .addfavorites {
    display: inline-block;
  }
  @media screen and (max-width: 1000px) {
    .listcont .right {
      width: 75%;
    }
    .listcont .exhibitor .info {
      width: 100%;
    }
    .listcont .products {
      visibility: hidden;
    }
  }

  @media screen and (max-width: 1000px) {
    .columncont {
      width: 44%;
    }
    .columncont .left {
      width: 90%;
    }
    .columncont .products .imgcont {
      height: 60px;
      width: 12%;
    }
    .infocont {
      width: 90%;
    }
  }
  @media screen and (max-width: 700px) {
    .listcont .right {
      width: 68%;
    }
    .listcont .left img {
      height: 53px;
    }
  }

  @media screen and (max-width: 500px) {
    .columncont .products .imgcont {
      height: 40px;
      width: 4%;
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 430px) {
    .listcont .right {
      width: 63%;
    }
    .listcont .left img {
      height: 50px;
    }
    .columncont {
      width: 40%;
    }
    .columncont .left {
      width: 85%;
    }
    .columncont .exhibitor h3 {
      padding: 0;
    }
    .columncont h3 {
      height: 30px;
      margin: 0%;
      height: 50px;
      font-size: 13px;
    }
  }
</style>
