<template>
  <div class="itemcont" :class="defineStytle()">
    <Banner :type="3" v-if="data.banner" :info="data.info" />

    <div v-else>
      <div @click="showMore()">
        <h4 class="contrast_color fifth_bg_color">
          <i :class="defineSubtype()"></i> {{ data.fullName }}
        </h4>
        <div class="imgcont">
          <img :src="files + data.picture" alt="" />
        </div>
        <div class="description">
          <p
            v-for="(text, index) in checkText.split(/<br\s*\/?>|\n/g)"
            :key="index"
          >
            {{ text }}
          </p>
        </div>
        <ProgressBar
          :sum="data.list.invested_sum"
          :max="data.list.raised_investment"
          v-if="
            data.type == 'opportunity' &&
              data.optype == 1 &&
              data.list.raised_investment > 0
          "
        />
        <!-- <p>
          {{ checkText }}
        </p> -->
        <div
          class="info seventh_bg_color_transparent container-text"
          v-if="defineType.length > 0"
        >
          <div v-for="(item, index) in defineType" :key="index">
            <span>{{ item.title + ': ' }}</span
            >{{ item.value }}
          </div>
        </div>

        <div
          class="triangle border_primary contrast_color"
          v-if="data.source == 'private'"
        >
          <i class="icon fas fa-university"></i>
        </div>
      </div>

      <div v-if="logged" class="addfavorites">
        <button @click="addToMyBriefcase()" v-if="show">
          <i class="fas fa-bookmark primary_color"></i>
        </button>
        <button @click="addToMyBriefcase()" v-else>
          <i class="far fa-bookmark fifth_color"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Banner from '@/components/Banner.vue'
  import ProgressBar from '@/components/ProgressBar.vue'

  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'ColumnItem',
    data() {
      return {
        show: false,
      }
    },
    components: {
      Banner,
      ProgressBar,
    },
    props: {
      data: {
        type: Object,
        default: {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      ...mapActions([
        'changeToolSelected',
        'saveActualTrend',
        'setMessage',
        'saveActualNews',
        'checkMyFavorites',
      ]),
      defineSubtype() {
        let clas = ''
        if (this.data.type == 'opportunity' || this.data.type == 'news') {
          switch (this.data.subtype) {
            case 'investment':
            case 'bidding':
              clas = 'fas fa-coins'
              break
            case 'partnership':
              clas = 'fas fa-handshake'

              break
            case 'export_import':
              clas = 'fas fa-truck'
              break
            case 'job offer':
              clas = 'fas fa-hands-helping'
              break
          }
        } else if (this.data.type == 'trends') {
          switch (this.data.format) {
            case 'pdf':
              clas = 'far fa-file-alt'
              break
            case 'video':
              clas = 'fas fa-video'

              break
            case 'streaming':
              clas = 'far fa-play-circle'

              break
          }
        }

        return clas
      },
      showMore() {
        // if (this.logged) {
        switch (this.data.type) {
          case 'trends':
            router.push('/presentations/' + this.data.code)

            break
          case 'opportunity':
            router.push('/investment/' + this.data.code)

            break
          case 'news':
            this.saveActualNews(this.data)
            this.changeToolSelected(26)
            break
        }
        // } else {
        //   this.setMessage({ msg: 'msg_need_to_be_logged', to: 'login' })
        //   this.changeToolSelected(0)
        // }
      },

      defineStytle() {
        let i = this.index
        let clas = ''
        let num = 2
        let sumorrest = true
        do {
          if (this.index > num) {
            if (sumorrest) {
              i++
            } else {
              i--
            }
          }
          sumorrest = !sumorrest
          num = num + 3
        } while (this.index >= num)
        if (this.data.banner) {
          clas = 'banner'
        } else {
          clas = i % 2 ? 'nineth_bg_color' : 'seventh_bg_color'
        }

        if (this.data.type == 'news') {
          clas += ' news'
        }
        return clas
      },

      async addToMyBriefcase() {
        var ls = localStorage.getItem('myFavorites')
        ls = ls ? JSON.parse(ls) : { items: [] }
        let find = false
        let i = 0
        let index = null
        if (ls.items.length > 0) {
          ls.items.forEach((element) => {
            if (
              element.code == this.data.code &&
              element.type == this.data.type
            ) {
              find = true
              index = i
            }
            i++
          })
        }
        if (!find) {
          ls.items.push({ code: this.data.code, type: this.data.type })
        } else {
          ls.items.splice(index, 1)
        }
        localStorage.setItem('myFavorites', JSON.stringify(ls))
        await this.checkMyFavorites()
      },
      isInMyBriefcase() {
        var ls = localStorage.getItem('myFavorites')
        ls = ls ? JSON.parse(ls) : { items: [] }
        let find = false
        ls.items.forEach((element) => {
          if (
            element.code == this.data.code &&
            element.type == this.data.type
          ) {
            find = true
          }
        })
        this.show = find
      },
    },
    computed: {
      ...mapState([
        'logged',
        'files',
        'logged',
        'myfavoritesBack',
        'translations',
      ]),
      checkText() {
        return this.data.description.replace(/<br\s*\/?>/g, '')
      },
      defineType() {
        let info = []
        switch (this.data.type) {
          case 'opportunity':
            info.push({
              title: this.translations.label_exhibitor,
              value: this.data.exhibitorName,
            })
            if (this.data.subtype != 'job offer') {
              info.push(
                {
                  title: this.translations.label_raised_investment,
                  value: this.data.list.raised_investment,
                },
                {
                  title: this.translations.label_investors,
                  value: this.data.investors,
                },
                {
                  title: this.translations.label_minimum_investment,
                  value: this.data.list.minimum_investment,
                }
              )
            } else {
              info.push(
                {
                  title: this.translations.label_minimum_wage,
                  value: this.data.list.minimum_investment,
                },
                {
                  title: this.translations.label_max_salary,
                  value: this.data.list.hardlimit_investment,
                }
              )
            }
            break

          case 'trends':
            info.push(
              {
                title: this.translations.label_trend_language,
                value: this.data.list.language_voice,
              },
              {
                title: this.translations.label_trend_subtitles,
                value: this.data.list.language_text,
              },
              {
                title: this.translations.label_start_time,
                value: this.data.list.start_time,
              }
            )
            break

          default:
            break
        }
        return info
      },
    },
    created() {
      this.isInMyBriefcase()
    },
    watch: {
      'myfavoritesBack.items'(newValue, oldValue) {
        this.isInMyBriefcase()
      },
    },
  }
</script>

<style scoped>
  .itemcont {
    position: relative;
    margin: 10px;
    padding: 10px;
    height: 530px;
    vertical-align: top;
    display: inline-block;
    width: 29%;
  }
  .news {
    height: 450px;
  }
  .imgcont {
    width: 100%;
    height: 205px;
    position: relative;
    margin: 20px 0;
  }

  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    height: 205px;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .triangle {
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid solid solid solid;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .triangle svg {
    position: absolute;
  }

  h4 {
    padding: 5px;
    margin: 0;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }
  h4 svg {
    position: absolute;
    left: 5px;
    top: 29%;
  }
  .info {
    text-align: left;
    padding: 5px;
  }
  .info span {
    font-weight: bold;
  }
  .container-text,
  .description {
    overflow: hidden;
    height: 70px;
  }
  .container-text {
    height: auto;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }
  @media screen and (max-width: 930px) {
    .itemcont {
      width: 40%;
      /* margin: 0; */
      /* padding: 0; */
    }
  }
  @media screen and (max-width: 650px) {
    .itemcont {
      width: 90%;
    }
  }
</style>
