<template>
  <div id="myProgress" class="secundary_bg_color">
    <div
      id="myBar"
      ref="bar"
      :style="'width:' + limitStyle + '%'"
      class="contrast_color primary_bg_color"
    >
      {{ percentage }}%
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProgressBar',
    props: {
      max: {
        type: Number,
        default: 0,
      },
      sum: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      percentage() {
        let result = Math.round((this.sum / this.max) * 100)
        if (isNaN(result) || !isFinite(result)) {
          result = 0
        }
        return result
      },
      limitStyle() {
        let result = this.percentage
        if (result < 0) {
          result = 0
        } else if (result > 100) {
          result = 100
        }
        return result
      },
    },
  }
</script>

<style scoped>
  #myProgress {
    width: 100%;
  }

  #myBar {
    /* width: 10%; */
    text-align: center;
    font-size: 12px;
    /* height: 30px; */
  }
</style>
