<template>
  <div>
    <div class="resultcont" v-if="filteredList.length > 0">
      <div class="result">
        {{ translations.label_filters_results }} : {{ filteredList.length }}
      </div>
      <ListStyle
        :selected="style"
        @changeSelected="changeStyleSelected"
        class="liststyle"
        v-if="checkType()"
      />
    </div>
    <div
      class="listitemscont"
      :class="show ? 'filterShowed' : ''"
      v-if="checkList() == 0"
    >
      <ListItem
        v-for="(item, index) in bannerList"
        :key="index"
        :item="item"
        :style="style"
        :type="type"
        class="mouseHover"
      />
    </div>
    <div class="items" v-else-if="checkList() == 1">
      <ColumnItem
        v-for="(item, index) in bannerList"
        :key="index"
        :data="item"
        :index="index"
        class="mouseHover item"
      />
    </div>
    <div class="items" v-else-if="checkList() == 2">
      <Card
        :style="0"
        v-for="(card, index) in filteredList"
        :key="index"
        :data="card"
        :type="dataType"
        class="mouseHover"
      />
    </div>
    <div class="items" v-else-if="checkList() == 3">
      <ListItem
        v-for="(item, index) in filteredList"
        :key="index"
        :item="{
          description: item.description,
          picture: item.picture,
          title: item.fullname,
          code: item.code,
          type: item.type,
        }"
        :favorites="false"
        :style="style"
        :type="type"
        class="mouseHover"
      />
      <!-- @click="linkTo(item.code, item.type)" -->
    </div>
    <div v-else class="message error_color">
      {{ translations.error_list_nodata }}
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import router from '../router'
  import ListItem from '@/components/ListItem.vue'
  import ColumnItem from '@/components/ColumnItem.vue'
  import Card from '@/components/Card.vue'
  import ListStyle from '@/components/ListStyle.vue'

  export default {
    name: 'List',
    components: {
      ListItem,
      ColumnItem,
      Card,
      ListStyle,
    },
    emits: ['updateStyle'],

    props: {
      filteredList: {
        type: Object,
        default: {},
      },
      style: {
        type: Number,
        default: 0,
      },
      type: {
        type: String,
        default: '',
      },
      show: {
        type: Boolean,
        default: false,
      },
      dataType: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState(['allData', 'translations']),
      bannerList() {
        let location = 0
        if (this.type == 'product' || this.type == 'exhibitor') {
          location = 2
        } else if (
          this.type == 'opportunity' ||
          this.type == 'news' ||
          this.type == 'trend'
        ) {
          location = 3
        }

        let result = this.filteredList
        if (this.filteredList.length > 5) {
          for (let i = 0; i < this.filteredList.length; i++) {
            if (i % 5 == 4) {
              let banner = null
              if (this.allData.banners) {
                let filterBanners = this.allData.banners.filter(
                  (banner) => banner.location == location
                )
                if (filterBanners.length > 0) {
                  banner =
                    filterBanners[
                      Math.floor(Math.random() * filterBanners.length)
                    ]
                  result.splice(i, 0, { banner: true, info: banner })
                }
              }
            }
          }
        }
        console.log(result)
        return result
      },
    },
    methods: {
      ...mapActions(['changeToolSelected']),
      linkTo(id, type) {
        switch (type) {
          case 'product':
            router.push('/product/' + id)
            this.changeToolSelected(null)

            break
          case 'exhibitor':
            router.push('/exhibitor/' + id)

            this.changeToolSelected(null)
            break

          default:
            break
        }
      },
      checkType() {
        let result = true
        console.log(this.dataType)
        if (
          this.dataType == 'trend' ||
          this.dataType == 'news' ||
          this.dataType == 'opportunity' ||
          this.dataType == 'card' ||
          this.dataType == 'cardexhibitor'
        ) {
          result = false
        }
        return result
      },
      checkList() {
        let result = false

        // if (
        //   this.data.list &&
        //   this.data.list.search &&
        //   this.data.list.search.result != 0
        // ) {
        if (this.type == 'product' || this.type == 'exhibitor') {
          result = 0
        } else if (
          this.type == 'opportunity' ||
          this.type == 'news' ||
          this.type == 'trend'
        ) {
          result = 1
          //   }
        } else if (this.type == 'card' || this.type == 'cardexhibitor') {
          result = 2
        } else if (this.type == 'favorite') {
          result = 3
        }
        return result
      },

      changeStyleSelected(id) {
        this.$emit('updateStyle', id)
        // this.style = id
      },
    },
  }
</script>

<style scoped>
  .resultcont {
    right: 10px;
    height: 50px;
    line-height: 60px;
    text-align: right;
  }
  .result,
  .liststyle {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
</style>
