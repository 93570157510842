<template>
  <div>
    <!-- <div v-if="logged" class="exhibtoolbar">
      Your status: <b>busy till 16:15</b> Chat waitlist:
      <b>waiting chats</b> Next meeting: <b>Today at 17:00</b>
    </div> -->
    <div class="unregistered primary_bg_color" v-if="!logged">
      <div
        v-for="(tool, index) in unregistered"
        :key="tool.id"
        class="container"
      >
        <span v-if="index > 0"> {{ translations.label_separator_or }} </span>
        <button
          class="contrast_color"
          v-if="checkTool(tool.id)"
          @click="select(tool.id)"
        >
          {{ tool.title }}
        </button>
      </div>
    </div>
    <div class="registered primary_bg_color" v-else>
      <div v-for="tool in registered" :key="tool.id" class="container">
        <button
          class="contrast_color notif"
          v-if="checkTool(tool.id)"
          @click="select(tool.id)"
          :class="defineMargin()"
        >
          <span class="iconcont">
            <i :class="tool.icon"></i>
            <span
              v-if="
                (tool.id == 12 && checkMessages(tool.id) > 0) ||
                  (tool.id == 13 && checkMessages(tool.id) > 0)
              "
              class="alert num"
              >{{ checkMessages(tool.id) }}</span
            >
          </span>
          {{ showTex(tool.title) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    data() {
      return {
        unregistered: [],
        registered: [],
        window: {
          width: 0,
        },
      }
    },
    props: {
      tools: {
        type: Array,
        default: [0, 1, 10, 11, 12, 14],
      },
    },
    computed: {
      ...mapState(['logged', 'infoMessages', 'chatMessages', 'translations']),
      checkSize() {
        return this.window.width <= 1100
      },
    },
    methods: {
      ...mapActions(['changeToolSelected']),
      checkTool(id) {
        return this.tools.includes(id)
      },
      select(id) {
        this.changeToolSelected(id)
      },
      showTex(value) {
        if (!this.checkSize) {
          return value
        }
      },
      handleResize() {
        this.window.width = window.innerWidth
      },
      defineMargin() {
        if (this.checkSize) {
          return 'mobile'
        }
      },
      checkMessages(id) {
        let result = 0
        switch (id) {
          case 12:
            let chatlist = JSON.parse(localStorage.getItem('chat'))
            if (chatlist) {
              this.chatMessages.forEach((alllist) => {
                alllist.alias.forEach((element) => {
                  chatlist.chats.forEach((backlist) => {
                    if (element.id == backlist.id) {
                      result += element.n_message - backlist.n_message
                    }
                  })
                })
              })
            }

            break
          case 13:
            this.infoMessages.forEach((element) => {
              if (element.show) {
                result++
              }
            })
            break
        }
        return result
      },
      addTranslations() {
        console.log(this.translations)
        if (this.translations) {
          this.unregistered = [
            { id: 0, title: this.translations.nav_login },
            { id: 1, title: this.translations.nav_regist },
          ]
          this.registered = [
            {
              id: 10,
              title: this.translations.nav_myaccount,
              icon: 'far fa-id-card',
            },
            {
              id: 11,
              title: this.translations.nav_mybriefcase,
              icon: 'fas fa-briefcase',
            },
            {
              id: 12,
              title: this.translations.nav_help,
              icon: 'far fa-comments',
            },
            {
              id: 13,
              title: this.translations.nav_mycalendar,
              icon: 'far fa-calendar-alt',
            },
            {
              id: 14,
              title: this.translations.nav_logout,
              icon: 'fas fa-sign-out-alt',
            },
          ]
        }
      },
    },

    created() {
      this.addTranslations()
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    unmounted() {
      window.removeEventListener('resize', this.handleResize)
    },
  }
</script>

<style scoped>
  .unregistered,
  .registered {
    text-align: center;
  }
  .unregistered .container,
  .registered .container {
    display: inline-block;
  }
  button {
    margin: 5px;
    padding: 6px;
    border: 1px;
  }
  .iconcont {
    position: relative;
  }
  .num {
    position: absolute;
    background-color: red;
    border-radius: 10px;
    font-size: 10px;
    right: -3px;
    padding: 2px 4px;
    min-width: 10px;
  }
  .mobile {
    margin: 5px 13px;
  }
  .exhibtoolbar {
    text-align: center;
    background-color: #e9e8e8;
  }

  .alert {
    animation-name: finishing;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: finishing;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
  }

  @-moz-keyframes finishing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes finishing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes finishing {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
