<template>
  <a
    :href="info.url_link"
    class="bannerimg"
    target="_blank"
    :style="{
      'background-image': `url(${files + definePicture})`,
    }"
  >
  </a>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'Banner',
    props: {
      type: {
        type: Number,
        default: 0,
      },
      info: {
        type: Object,
        default: {},
      },
    },
    computed: {
      ...mapState(['files']),
      definePicture() {
        let picture = ''
        if (this.info.url_image) {
          picture = this.info.url_image
        } else {
          switch (this.type) {
            case 1:
              picture = '1100x100.png'
              break
            case 2:
              picture = '946x157.png'
              break
            case 3:
              picture = '350x550.png'
              break
          }
        }
        return picture
      },
    },
  }
</script>

<style scoped>
  .bannerimg {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }
</style>
