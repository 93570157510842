<template>
  <div :class="checkStyle">
    <div class="card border_fourth front" @click="saveCard">
      <div class="imgcont">
        <img :src="files + data.picture" :alt="data.fullName" />
        <div
          class="logo"
          :style="{
            'background-image': `url(${files +
              data.businessCode +
              '-logo.png'})`,
          }"
          @error="onImgError()"
          v-if="data.businessCode && showLogo"
        ></div>
      </div>
      <div class="info" v-if="data">
        <h3>{{ data.fullName }}</h3>
        <p class="primary_color">
          <span @click="linkTo()"> {{ data.business }} </span>
        </p>
        <p>{{ data.position }}</p>
      </div>
      <div
        class="triangle_top nineth_color"
        @click="removeCard()"
        v-if="type == 'card' && !myCard"
      >
        <div class="primary_color">
          <i class="far fa-trash-alt"></i>
        </div>
      </div>
      <div
        class="triangle nineth_color"
        @click="chatTo()"
        v-if="type == 'card' && !myCard"
      >
        <div class="primary_color">
          <i class="far fa-comment-dots"></i>
        </div>
      </div>

      <div class="triangle primary_color" v-if="type != 'card' && saved"></div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Card',
    data() {
      return {
        cardPosition: true,
        showLogo: true,
        saved: false,
      }
    },
    props: {
      style: {
        type: Number,
        default: 0,
      },
      data: {
        type: Object,
        default: {
          picture: 'huawei.png',
          fullName: 'Adrià Gómez Roca',
          business: 'Bressol Creatiu',
          position: 'Developer',
        },
      },
      myCard: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'card',
      },
      position: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      ...mapActions([
        'changeToolSelected',
        'addContact',
        'setClickCard',
        'checkMyCards',
        'getCards',
      ]),
      linkTo() {
        router.push('/exhibitor/' + this.data.businessCode)
        this.changeToolSelected(null)
      },
      async chatTo() {
        await this.addContact(this.data.code)
        this.changeToolSelected(12)
        await this.setClickCard(this.data.code)
      },
      onImgError() {
        this.showLogo = false
      },
      saveCard() {
        if (this.type != 'card') {
          var ls = localStorage.getItem('myCards')
          ls = ls ? JSON.parse(ls) : { items: [] }
          let find = false
          let i = 0
          let index = null
          if (ls.items && ls.items.length > 0) {
            ls.items.forEach((element) => {
              if (element.code == this.data.code) {
                find = true
                index = i
              }
              i++
            })
          }
          if (!find) {
            ls.items.push({ code: this.data.code })
            this.checkMyCards({
              code: this.data.code,
              action: 'pickcard',
            })
          } else {
            ls.items.splice(index, 1)
            this.checkMyCards({ code: this.data.code, action: 'discard' })
          }
          this.changeToolSelected(null)

          localStorage.setItem('myCards', JSON.stringify(ls))
          this.checkSelected()
        }
      },
      checkSelected() {
        if (this.type != 'card') {
          var ls = localStorage.getItem('myCards')
          ls = ls ? JSON.parse(ls) : { items: [] }
          let find = false

          if (ls.items && ls.items.length > 0) {
            ls.items.forEach((element) => {
              if (element.code == this.data.code) {
                find = true
              }
            })
          }
          this.saved = find
        }
      },
      async removeCard() {
        await this.checkMyCards({
          code: this.data.code,
          action: 'discard',
        })
        this.getCards()
      },
    },
    computed: {
      ...mapState(['files']),
      checkStyle() {
        let clas
        switch (this.style) {
          case 0:
            clas = 'meshcont'
            break
          case 1:
            clas = 'list'
            break

          default:
            break
        }
        return clas
      },
    },
    created() {
      if (!this.data.picture) {
        this.data.picture = 'default.png'
      }
      if (this.position) {
        this.cardPosition = this.position
      }
      this.checkSelected()
    },
  }
</script>

<style scoped>
  .triangle {
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid solid solid solid;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .triangle_top {
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid solid solid solid;
    border-color: transparent !important;
    position: absolute;
    right: 0;
    top: 0;
  }
  .triangle_top:hover svg {
    color: #db7373d7;
  }
  .triangle:hover {
    color: #a7a7a7;
  }
  .triangle_top svg {
    color: #d25252;
    top: -1rem;
  }
  .triangle svg,
  .triangle_top svg {
    position: absolute;
  }
  .meshcont {
    width: 290px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }
  /* .list {
    width: 70%;
    text-align: center;
    margin: 0 auto;
  } */
  .card {
    border-width: 1px;
    border-style: solid;
    /* border-radius: 10px; */
    text-align: center;
    margin: 10px;
    position: relative;
  }
  .meshcont .card {
    height: 110px;
  }
  /* .list .card {
    height: 150px;
  } */
  .card img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 95px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    word-wrap: break-word;
    font-size: 10px;
    overflow: hidden;
  }
  .meshc .front {
    text-align: left;
    height: 110px;
  }
  /* .list .front {
    text-align: center;
    height: 150px;
  } */

  .front img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .imgcont,
  .info {
    display: inline-block;
    vertical-align: middle;
  }

  .imgcont {
    position: relative;
  }
  .meshcont .front .imgcont {
    height: 110px;
    width: 60px;
    margin: auto 10px;
  }
  .meshcont .front .info {
    width: 70%;
  }
  /* .list .front .imgcont {
    width: 90px;
    height: 150px;
    margin: auto 20px;
  } */
  .logo {
    height: 20px;
    width: 20px;
    position: absolute;
    border-radius: 100%;
    /* background-image: url('https://www-file.huawei.com/-/media/corp/home/image/logo_400x200.png') !important; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 5px;
    right: 0;
  }
  /* .list h3 {
    font-size: 30px;
  } */
  h3 {
    font-size: 15px;
    text-align: left;
  }
  p {
    text-align: left;
  }
  .meshcont p {
    font-size: 12px;
  }
  @media screen and (max-width: 750px) {
    .list {
      width: 100%;
    }
    .list .front .imgcont {
      margin: auto 5px;
    }
    .list .info {
      width: 45%;
    }
  }
  @media screen and (max-width: 550px) {
    .list h3 {
      font-size: 20px;
    }
    .list p {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .list h3 {
      font-size: 16px;
    }
  }
</style>
