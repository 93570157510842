<template>
  <div class="whitecontainer border_category" :class="defineStyle" onclick="">
    <div v-if="type == 'home'" class="homehover">
      <div class="homefront">
        <div class="contrast_color primary_bg_color titlecont">
          <div class="titlecenter">
            <h4>
              {{ category.title }}
            </h4>
          </div>
        </div>
        <div
          class="img"
          :style="{
            'background-image': 'url(' + files + category.img + ')',
          }"
        ></div>
      </div>
      <div class="homeback  contrast_color primary_bg_color">
        <ul
          v-for="(item, index) in category.list.slice(0, limitList)"
          :key="index"
          @click="linkTo(item)"
        >
          <!-- <router-link
          :to="defineURL()"
          @click="defineData({ category_id: item.id })"
        > -->
          <li class="categorylist" :class="defineDecoration">
            {{ item.item }}
          </li>
          <!-- </router-link> -->
        </ul>
      </div>
    </div>

    <div v-if="type == 'columns'">
      <h4 class="fourth_color">
        {{ category.title }}
      </h4>
      <div class="fourth_color" v-if="category.subtitle">
        ({{ category.subtitle }})
      </div>
      <!-- <img class="categoryimg" :alt="checkTitle" :src="files + category.img" /> -->
    </div>
    <div v-else-if="type == 'default'" class="iconleft">
      <ExhibitorTool
        :tool="{
          icon: icon.icon,
          text: icon.text + ' (' + categoryList.length + ')',
        }"
        :border="false"
        :bolder="true"
        :svg="true"
      />
    </div>
    <div
      class="left"
      v-if="type == 'default' || type == 'columns'"
      :class="type == 'columns' ? '' : 'listright'"
    >
      <p v-if="category.description">{{ category.description }}</p>
      <hr v-if="type == 'columns'" />

      <div v-if="limitList != -1 && !link">
        <ul :class="defineDecoration">
          <li
            class="categorylist hover"
            v-for="(item, index) in category.list.slice(0, limitList)"
            :key="index"
            @click="linkTo(item)"
          >
            {{ item.item }}
          </li>
        </ul>

        <button v-if="checkLength && showButton" :class="defineDecoration">
          {{ replaceTranslation({ type: 'showmore', text: numberlist }) }}
        </button>
      </div>
      <ul
        v-else
        v-for="(item, index) in categoryList.slice(0, limitList)"
        :key="index"
        @click="linkTo(item)"
      >
        <!-- <router-link
          :to="defineURL()"
          @click="defineData({ category_id: item.id })"
        > -->
        <li class="categorylist" :class="defineDecoration">
          {{ item.item }}
        </li>

        <!-- </router-link> -->
      </ul>
    </div>
  </div>
</template>

<script>
  import ExhibitorTool from '@/components/ExhibitorTool.vue'
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    components: {
      ExhibitorTool,
    },
    data() {
      return {
        numberlist: 0,
        show: false,
      }
    },
    props: {
      category: {
        type: Object,
        default: {
          title: 'text',
          subtitle: 100,
          description: '',
          img: '',
          list: [
            { id: '', item: 'Electronics' },
            { id: '', item: 'e-Learning' },
            { id: '', item: 'Games' },
            { id: '', item: 'Sport & Fitness' },
          ],
        },
      },
      icon: {
        type: Object,
        default: { text: 'Phones', icon: 'fas fa-mobile-alt' },
      },
      index: {
        type: Number,
        default: 0,
      },
      decoration: {
        type: Boolean,
        default: false,
      },
      showButton: {
        type: Boolean,
        default: true,
      },
      link: {
        type: Boolean,
        default: false,
      },
      limitList: {
        type: Number,
        default: -1,
      },
      type: {
        type: String,
        default: 'default',
      },
    },
    computed: {
      ...mapState(['files', 'translations']),
      categoryList() {
        return this.category.list.filter(
          (item) => item.code != this.$route.params.id
        )
      },
      checkLength() {
        let result = false
        if (this.category.list.length > this.limitList) {
          result = true
          this.numberlist = this.category.list.length - this.limitList
        }
        return result
      },
      defineDecoration() {
        return this.decoration ? 'decorated' : 'nodecorated'
      },
      defineStyle() {
        let i = this.index
        let clas = ''
        let num = 2
        let sumorrest = true
        if (this.type == 'home' && !this.show) {
          clas = 'homecontainer'
        } else if (this.type == 'home' && this.show) {
          // clas = 'homecontainerback'
        } else {
          do {
            if (this.index > num) {
              if (sumorrest) {
                i++
              } else {
                i--
              }
            }
            sumorrest = !sumorrest
            num = num + 3
          } while (this.index >= num)
          let numborder = 4
          do {
            if (this.index == numborder && this.limitList != -1) {
              clas = 'darkcontainerborder eighth_bg_color border_category'
            } else {
              clas = i % 2 ? 'darkcontainer eighth_bg_color' : 'whitecontainer'
            }
            numborder = numborder + 3
          } while (this.index >= numborder)
        }

        return clas
      },
    },
    methods: {
      ...mapActions(['getExhibitors', 'getProducts']),
      showText() {
        this.show = !this.show
      },
      async linkTo(item) {
        if (item.id) {
          if (this.type == 'home') {
            await this.getProducts({ category_id: item.id })
            router.push('/productlist')
          } else {
            await this.getExhibitors({ category_id: item.id })
            router.push('/exhibitorlist')
          }
          // if (this.category.product) {
          // } else {
          //   await this.getExhibitors({ category_id: item.id })
          //   router.push('/exhibitorlist')
          // }
        } else if (item.code) {
          router.push('/exhibitor/' + item.code)
        }
      },
      replaceTranslation(value) {
        let result = ''
        switch (value.type) {
          case 'showmore':
            result = this.translations.btn_showmore_numeric.replace(
              '{var}',
              value.text
            )
            break
        }

        return result
      },
    },
  }
</script>

<style scoped>
  .homecontainer .titlecont {
    display: table;
    height: 98px;
    overflow: hidden;
  }
  .homecontainer .titlecenter {
    display: table-cell;
    vertical-align: middle;
  }
  h4 {
    margin-bottom: 0;
    font-style: normal;
    font-size: 20px;
    line-height: 1.2;
    height: 50px;
  }

  .darkcontainer,
  .whitecontainer {
    text-align: center;
    width: 100%;
    border-radius: 1px;
  }
  .whitecontainer {
    border-width: 1px;
    border-style: solid;
  }
  .darkcontainerborder {
    border-width: 1px;
    border-style: solid none none none;
  }
  .darkcontainer {
    border: 1px solid transparent;
  }
  .categoryimg {
    width: 87%;
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    margin-top: 10px;
  }
  .left {
    text-align: left;
    padding: 0 10px 0 20px;
  }

  ul {
    padding-left: 15px;
    margin: 0;
    cursor: pointer;
  }
  .categorylist {
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .nodecorated {
    list-style-type: none;
  }
  .decorated {
    text-decoration: underline;
  }
  .iconleft,
  .listright {
    display: inline-block;
    vertical-align: top;
  }
  .iconleft {
    width: 47%;
  }
  .listright {
    width: 40%;
  }
  /* HOME */
  .homecontainer {
    border-color: transparent;
    height: 265px !important;
  }
  .homehover {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
    height: 257px;
  }
  .homecontainer h4 {
    font-size: 20px;
    padding: 0 5px;
    text-align: left;
    margin: 0;
    font-style: normal;
    line-height: normal;
    height: auto;
    max-height: 55px;
    overflow: hidden;
  }
  .homecontainer .img {
    margin: 0;
    width: 100%;
    height: 80%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .homecontainer .left {
    visibility: hidden;
  }
  .homefront,
  .homeback {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .homeback {
    transform: rotateY(180deg);
  }
  .homecontainer:hover .homehover,
  .homecontainer:active .homehover {
    height: 255px;
    transform: rotateY(180deg);
    perspective: 1000px;

    /* transform: scale(1.1); */
  }
  .homeback ul {
    margin: 0;
    list-style: none;
    padding: 5px 3px;
  }
  .hover:hover {
    text-decoration: underline;
  }
</style>
