<template>
  <div class="toolcontent fifth_color" :class="define">
    <div
      class="svgicon"
      :style="`background-image: url(${files + checkImg})`"
      v-if="svg"
    ></div>
    <i :class="tool.icon + ' fa-' + iconChange + 'x'" v-else></i>
    <div :class="checkMultipleColor">
      <p :class="defineText">{{ tool.text }}</p>
      <div v-if="tool.description">
        <p v-if="Array.isArray(tool.description)" :class="defineBolder">
          <span v-for="(item, index) in tool.description" :key="index">
            <span v-if="index > 0">, </span>
            {{ item }}
          </span>
        </p>

        <p v-else :class="defineBolder">
          {{ tool.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      tool: {
        type: Object,
        default: {
          text: 'See list of all exhibitors',
          icon: 'far fa-eye',
          description: null,
        },
      },
      index: {
        type: Number,
        default: 1,
      },
      border: {
        type: Boolean,
        default: true,
      },
      bolder: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'big',
      },
      iconChange: {
        type: Number,
        default: 3,
      },
      multipleColors: {
        type: Boolean,
        default: false,
      },
      svg: {
        type: Boolean,
        default: false,
      },
      noMargin: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['files']),
      checkImg() {
        const regex = /^\S+$/gm
        let result = regex.exec(this.tool.icon) ? this.tool.icon : 'default.svg'
        return 'category/' + result
      },
      checkMultipleColor() {
        let clas = ''
        if (this.multipleColors) {
          if (this.index % 2) {
            clas = 'primary_color'
          } else {
            clas = 'fifth_color'
          }
        }
        return clas
      },
      defineBolder() {
        let clas = ''
        if (this.bolder) {
          clas = 'bolder'
        } else {
          clas = 'text'
        }
        return clas
      },
      defineBorder() {
        let clas = ''
        if (this.border) {
          if (this.index % 2) {
            clas = 'border_sixth'
          } else {
            clas = 'border_primary primary_color'
          }
        } else {
          if (this.index == -1) {
            clas = 'tertiary_color'
          } else if (!this.index % 2) {
            clas = 'primary_color'
          }
        }
        return clas
      },
      defineHeigth() {
        let clas = ''
        switch (this.size) {
          case 'big':
            clas = 'bigheigth'
            break
          case 'medium':
            clas = 'mediumheigth'
            break
          case 'small':
            clas = 'smallheigth'
            break

          default:
            break
        }
        return clas
      },
      defineMargin() {
        let clas = ''
        if (this.tool.description || this.noMargin) {
          clas = 'nomargin'
        } else {
          clas = 'margin'
        }
        return clas
      },
      define() {
        let result = ''
        if (this.defineMargin != 'nomargin') {
          result += this.defineHeigth
        }
        result += ' ' + this.defineBorder + ' ' + this.defineMargin
        return result
      },
      defineText() {
        return this.defineSize + ' ' + this.defineBolder
      },
      defineSize() {
        let clas = ''
        switch (this.size) {
          case 'big':
            clas = 'big'
            break
          case 'medium':
            clas = 'medium'
            break
        }
        return clas
      },
    },
  }
</script>

<style scoped>
  .svgicon {
    /* background-color: red; */
    height: 57px;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .toolcontent {
    padding: 10px;
    text-align: center;
  }
  .border_primary,
  .border_sixth {
    border: 1px solid;
  }
  .margin {
    margin: 9px;
  }
  .nomargin {
    margin: 0;
    /* height: 130px; */
  }
  .bigheigth {
    height: 150px;
    width: 140px;
  }

  .mediumheigth {
    height: 90px;
  }

  .big {
    font-size: 15px;
  }
  .medium {
    font-size: 11px;
    font-weight: bold;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .bolder {
    font-weight: bolder;
  }
</style>
