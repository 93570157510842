<template>
  <div class="filtercont">
    <div class="inputcont">
      <button
        class="btn primary_bg_color filter contrast_color"
        @click="showFilters()"
      >
        {{ checkText }}
        <span v-if="show">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-else>
          <i class="fas fa-sort-down"></i>
        </span>
      </button>
      <input
        class="border_fourth"
        type="text"
        :value="modelValue"
        :placeholder="translations.form_ph_filter_text"
        v-if="!show"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <div v-else class="inputfilters primary_bg_color border_fourth">
        <div class="filtertypecont">
          <input
            type="text"
            class="filtertext"
            :value="modelValue"
            :placeholder="translations.form_ph_filter_text"
            @input="$emit('update:modelValue', $event.target.value)"
          />
          <div
            v-if="
              data.filterOptions &&
                data.filterOptions.type &&
                data.filterOptions.type.length > 0
            "
            class="filtertype contrast_bg_color"
          >
            {{ translations.form_title_filter_types }}:

            <button
              v-for="(item, index) in data.filterOptions.type"
              :key="index"
              class="filterOpt contrast_color"
              :class="isSelected('type', item)"
              @click="applyFilter({ type: item })"
            >
              {{ item }}
            </button>
          </div>
          <div
            v-if="
              data.filterOptions &&
                data.filterOptions.public_sector &&
                data.filterOptions.public_sector.length > 0
            "
            class="filtertype contrast_bg_color"
          >
            {{ translations.form_title_filter_sector }}:

            <button
              v-for="(item, index) in data.filterOptions.public_sector"
              :key="index"
              class="filterOpt contrast_color"
              :class="isSelected('public_sector', item)"
              @click="applyFilter({ public_sector: item })"
            >
              {{ item }}
            </button>
          </div>
          <div
            v-if="
              data.filterOptions &&
                data.filterOptions.category &&
                data.filterOptions.category.length > 0
            "
            class="filtercategory contrast_bg_color"
          >
            <SelectMultiple
              v-model="categories"
              :title="translations.form_ph_categories"
              :values="data.filterOptions.category"
              name="languages"
              class="selector"
              :filter="true"
              @input="applyFilter('category')"
            />
          </div>

          <div
            v-if="
              data.filterOptions &&
                data.filterOptions.source &&
                data.filterOptions.source.length > 0
            "
            class="filtersource contrast_bg_color"
          >
            <h3>{{ translations.form_title_filter_sources }}:</h3>
            <button
              v-for="(item, index) in data.filterOptions.source"
              :key="index"
              class="filterOpt contrast_color"
              :class="isSelected('source', item)"
              @click="applyFilter({ source: item })"
            >
              {{ item }}
            </button>
          </div>
          <div
            v-if="data.filterOptions && data.filterOptions.operation"
            class="filtertype contrast_bg_color"
          >
            {{ translations.form_title_filter_trade }}:

            <button
              v-for="(item, index) in data.filterOptions.operation"
              :key="index"
              class="filterOpt contrast_color"
              :class="isSelected('operation', item)"
              @click="applyFilter({ operation: item })"
            >
              {{ item }}
            </button>
          </div>
          <div
            v-if="data.filterOptions.year && data.filterOptions.year.length > 0"
            class="filtertype contrast_bg_color"
          >
            {{ translations.form_title_filter_year }}:

            <input type="number" class="year" v-model="year.smallYear" />
            -
            <input type="number" class="year" v-model="year.bigYear" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SelectMultiple from '@/components/SelectMultiple.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'SearchFilters',
    components: {
      SelectMultiple,
    },
    data() {
      return {
        categories: [],

        // smallYear: null,
        // bigYear: null,
      }
    },
    emits: ['update:modelValue', 'updateShow'],

    props: {
      data: {
        type: Object,
        default: { list: {}, filterOptions: {} },
      },

      show: {
        type: Boolean,
        default: false,
      },

      modelValue: {
        type: String,
        default: '',
      },
      filteredList: {
        type: Object,
        default: {},
      },
      style: {
        type: Number,
        default: 1,
      },
      disabled: {
        type: Array,
        default: [],
      },
      year: {
        type: Object,
        default: {
          bigYear: null,
          smallYear: null,
        },
      },
    },
    computed: {
      ...mapState(['translations']),
      checkText() {
        return this.show
          ? this.translations.btn_hide_filters
          : this.translations.btn_add_filters
      },
    },
    methods: {
      showFilters() {
        this.$emit('updateShow', this.show)
      },

      isSelected(name, value) {
        let clas = ''
        let lengthList = 0

        if (this.filteredList) {
          switch (name) {
            case 'category':
              this.filteredList.filter((item) => {
                if (item.category) {
                  if (item.category.includes(value)) {
                    clas = 'primary_bg_color'
                  }
                } else if (item.categories) {
                  item.categories.forEach((element) => {
                    if (element.name) {
                      if (element.name.includes(value)) {
                        clas = 'primary_bg_color'
                      }
                    }
                  })
                }
              })
              lengthList = this.data.filterOptions.category.length

              break
            case 'type':
              this.filteredList.filter((item) => {
                if (item.subtype) {
                  if (item.subtype && item.subtype == value) {
                    clas = 'primary_bg_color'
                  }
                } else {
                  if (item.type && item.type.includes(value)) {
                    clas = 'primary_bg_color'
                  }
                }
              })
              lengthList = this.data.filterOptions.type.length
              break
            case 'public_sector':
              this.filteredList.filter((item) => {
                console.log(item.public_sector)
                console.log(value)
                if (item.public_sector == value) {
                  clas = 'primary_bg_color'
                }
              })
              lengthList = this.data.filterOptions.public_sector.length
              break
            case 'operation':
              this.filteredList.filter((item) => {
                if (item.operation && item.operation.includes(value)) {
                  clas = 'primary_bg_color'
                }
              })
              lengthList = this.data.filterOptions.operation.length
              break

            case 'source':
              this.filteredList.filter((item) => {
                if (item.source.includes(value)) {
                  clas = 'primary_bg_color'
                }
              })
              lengthList = this.data.filterOptions.source.length
              break
            default:
              break
          }
        }
        if (lengthList == 1) {
          clas = clas + ' one'
        }

        return clas
      },
      applyFilter(filter) {
        let find = false
        let index = null
        if (filter == 'category') {
          for (let i = 0; i < this.disabled.length; i++) {
            if (this.disabled[i].category) {
              this.disabled.splice(i, 1)
            }
          }

          this.categories.forEach((element) => {
            this.disabled.push({ category: element })
          })
        }
        if (Object.keys(filter)[0] == 'type') {
          for (let i = 0; i < this.disabled.length; i++) {
            if (this.disabled[i].type == filter.type) {
              find = true
              index = i
            }
          }
          if (!find) {
            this.disabled.push(filter)
          } else {
            this.disabled.splice(index, 1)
          }
        }
        if (Object.keys(filter)[0] == 'public_sector') {
          for (let i = 0; i < this.disabled.length; i++) {
            if (this.disabled[i].public_sector == filter.public_sector) {
              find = true
              index = i
            }
          }
          if (!find) {
            this.disabled.push(filter)
          } else {
            this.disabled.splice(index, 1)
          }
        }
        if (Object.keys(filter)[0] == 'operation') {
          for (let i = 0; i < this.disabled.length; i++) {
            if (this.disabled[i].operation == filter.operation) {
              find = true
              index = i
            }
          }
          if (!find) {
            this.disabled.push(filter)
          } else {
            this.disabled.splice(index, 1)
          }
        }
        if (Object.keys(filter)[0] == 'source') {
          for (let i = 0; i < this.disabled.length; i++) {
            if (this.disabled[i].source == filter.source) {
              find = true
              index = i
            }
          }
          if (!find) {
            this.disabled.push(filter)
          } else {
            this.disabled.splice(index, 1)
          }
        }
      },
    },
    watch: {
      categories(newValue, oldValue) {
        this.applyFilter('category')
      },
    },
  }
</script>

<style scoped>
  .inputcont {
    text-align: center;
  }

  input,
  .inputfilters {
    width: 80%;
    box-sizing: border-box;
    display: inline-block;
    padding: 3px 20px;
    /* font-family: inherit; */
    height: 50px;
    vertical-align: top;
    border-radius: 5px;
    border-width: thin;
    border-style: solid;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  /* .filtercont {
    min-height: 100px;
  } */
  .year {
    width: 20%;
    border-radius: 3px;
    padding: 0;
    text-align: center;
    height: 30px;
    vertical-align: middle;
  }
  .inputfilters {
    height: auto;
    padding: 3px;
  }
  .filtertypecont {
    text-align: left;
    text-align: center;
    /* width: 70%; */
  }
  .filtertypecont .filtertext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .filtertypecont .filtertype,
  .filtertypecont .filtersource,
  .filtertypecont .filtertext {
    margin: 3px;
    width: 44%;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
    box-sizing: initial;
  }
  .filtercategory {
    width: 99%;
    font-size: 15px;
    margin: 1px auto;
  }
  .filterShowed {
    margin-top: 50px;
  }
  .filterOpt {
    font-size: 15px;
    background-color: #d5d5d5;
    height: 26px;
  }
  .filterOpt:first-of-type {
    border-radius: 5px 0px 0px 5px;
  }
  .filterOpt:last-of-type {
    border-radius: 0px 5px 5px 0px;
  }
  .one {
    border-radius: 5px !important;
  }
  .filter {
    font-size: 15px;
    width: 115px;
    margin: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .filtertype,
  .filtersource {
    overflow: auto;
    white-space: nowrap;
    text-align: left;
    line-height: 50px;
    height: 50px;
    padding: 3px 20px;
  }

  .filtertype .filterOpt,
  .filtertype h3,
  .filtersource .filterOpt,
  .filtersource h3 {
    display: inline-block;
    vertical-align: middle;
  }

  .contresult {
    position: relative;
  }

  @media screen and (max-width: 1000px) {
    .filterShowed {
      margin-top: 100px;
    }
    input,
    .inputfilters {
      width: 70%;
    }
    .filtertypecont .filtertype,
    .filtertypecont .filtersource,
    .filtertypecont .filtertext {
      width: 92%;
      margin: 1px 0px;
    }
  }
  @media screen and (max-width: 750px) {
    .filtertype {
      width: 90%;
    }
    .filtertypecont {
      text-align: center;
      width: 100%;
    }
    .filtertypecont,
    .absolutecont {
      position: relative;
      display: block;
    }
    .absolutecont {
      text-align: right;
    }
  }
  @media screen and (max-width: 710px) {
    .filtertypecont .filtertype,
    .filtertypecont .filtersource,
    .filtertypecont .filtertext {
      width: 90%;
      margin: 1px 0px;
    }
  }
  @media screen and (max-width: 600px) {
    .filterShowed {
      margin-top: 170px;
    }
    .filtertypecont .filtertype,
    .filtertypecont .filtersource,
    .filtertypecont .filtertext {
      width: 88%;
      margin: 1px 0px;
    }
  }
  @media screen and (max-width: 455px) {
    input,
    .inputfilters {
      width: 65%;
    }
    .filtertypecont .filtertype,
    .filtertypecont .filtersource,
    .filtertypecont .filtertext {
      width: 82%;
      margin: 1px 0px;
    }
  }
  @media screen and (max-width: 400px) {
    input,
    .inputfilters {
      width: 75%;
    }
    .filter {
      width: 70px;
      font-size: 14px;
    }
  }
</style>
