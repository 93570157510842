<template>
  <footer>
    <Tools :tools="tools" :key="translations" />
    <div class="info tertiary_bg_color">
      <div class="copyright sixth_color">
        {{ translations.label_copyright }}
      </div>
    </div>
  </footer>
</template>

<script>
  import Tools from '@/components/Tools.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'Footer',
    components: {
      Tools,
    },

    computed: {
      ...mapState(['tools', 'translations']),
    },
  }
</script>

<style scoped>
  footer {
    line-height: 1.5;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  .info {
    text-align: center;
  }

  .copyright {
    padding: 10px;
    font-size: 8px;
  }
  .socialicons {
    position: absolute;
    right: 0;
    bottom: 3px;
  }
  .socialicons a {
    margin: 5px;
  }
</style>
