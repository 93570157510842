<template>
  <div class="calendarmsgcont">
    <div v-if="exhibitorCards.length > 0" class="calendarmsgcont">
      <div v-if="exhibitorCalendar.user" class="info seventh_bg_color">
        <div class="infocont">
          <div
            v-if="exhibitorCalendar.user.picture"
            class="img"
            :style="
              `background-image: url(${files +
                '/user/' +
                exhibitorCalendar.user.picture})`
            "
          ></div>
          <div class="textinfo">
            <h1 class="exhibitor">{{ exhibitorCalendar.user.fullName }}</h1>
            <div class="business">
              <p>{{ exhibitorCalendar.user.business }}</p>
              <p>{{ exhibitorCalendar.user.position }}</p>
            </div>
          </div>
        </div>
        <div class="changeexhibitor">
          <button @click="changeCalendar(-1)" v-if="position != 0">&lt;</button>
          <button v-if="position == 0"></button>
          <button
            @click="changeCalendar(1)"
            v-if="
              exhibitorCards.length > 1 && exhibitorCards.length != position + 1
            "
          >
            &gt;
          </button>
        </div>
      </div>
      <div class="calendarcont">
        <Calendar
          :rowSize="30"
          :events="exhibitorCalendar.calendar"
          :calendarUse="'exhibitorCal'"
          @msgEvent="addEvent"
          v-if="
            exhibitorCalendar.calendar && exhibitorCalendar.calendar.length > 0
          "
        />

        <div
          class="confirmcalendar contrast_bg_color_transparent"
          v-if="
            ((event.startTime || checkEvents()) &&
              exhibitorCards[0] != codeLocalStorage()) ||
              errors.calendar ||
              calendarconfirm
          "
        >
          <div
            class="calendarmsg border_tenth contrast_bg_color"
            v-if="event.startTime || errors.calendar || calendarconfirm"
          >
            <div class="text" v-if="event.startTime">
              <p>
                {{ translations.warning_book_day }}
              </p>
              <p class="datetime">
                {{ event.day }}
                {{ event.startTime }} to {{ event.endTime }}
              </p>
            </div>
            <div v-else-if="errors.calendar" class="text">
              <p>{{ errors.calendar }}</p>
            </div>
            <div v-else-if="calendarconfirm" class="text">
              <p>{{ checkSuccess }}</p>
            </div>
            <div class="buttonsform ">
              <button
                class="btn fourth_bg_color contrast_color"
                @click="processEvent('deny')"
                v-if="!calendarconfirm"
              >
                {{ translations.btn_cancel }}
              </button>
              <button
                :class="defineStylePopUp + ' contrast_color'"
                @click="processEvent('confirm')"
                v-if="!errors.calendar"
              >
                {{ defineButton }}
              </button>
            </div>
          </div>
          <div
            class="calendarmsg border_tenth contrast_bg_color"
            v-else-if="checkEvents()"
          >
            <div class="text">
              <p>
                {{ translations.warning_calendar_hours_available }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="errorcont">
      <div class="message error_color">
        {{ translations.warning_calendar_exhibitors }}
      </div>
    </div>
    <div class="buttonsform contrast_bg_color">
      <button
        @click="removeSelected()"
        class="btntool fourth_bg_color contrast_color"
      >
        {{ translations.btn_close }}
      </button>
    </div>
  </div>
</template>

<script>
  import Calendar from '@/components/Calendar.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ToolCalendar',
    components: {
      Calendar,
    },
    data() {
      return {
        type: 'week',
        position: 0,
        event: {},
        find: false,
      }
    },
    computed: {
      ...mapState([
        'exhibitorCalendar',
        'exhibitorCards',
        'files',
        'errors',
        'calendarconfirm',
        'translations',
      ]),
      checkSuccess() {
        return typeof this.calendarconfirm == 'string'
          ? this.calendarconfirm
          : this.translations.warning_calendar_success
      },
      defineStylePopUp() {
        let clas = ''
        if (this.event.startTime || this.calendarconfirm) {
          clas = 'btn primary_bg_color'
        } else if (this.errors.calendar) {
          clas = 'btn secundary_bg_color'
        }
        return clas
      },
      defineButton() {
        let text = this.translations.btn_reserve
        if (this.calendarconfirm) {
          text = this.translations.btn_confirm
        }
        return text
      },
    },
    methods: {
      ...mapActions([
        'getExhibitorCalendar',
        'addToMyCalendar',
        'resetError',
        'changeToolSelected',
        'setCalendarConfirm',
      ]),
      defineType() {
        return (this.type = this.type == 'week' ? 'day' : 'week')
      },
      removeSelected() {
        this.changeToolSelected(null)
      },
      addEvent(event) {
        console.log('GMT +user : ' + event.startTime)
        this.event = event
      },
      codeLocalStorage() {
        return JSON.parse(localStorage.getItem('loged')).code
      },
      processEvent(option) {
        switch (option) {
          case 'confirm':
            // var ls = localStorage.getItem('loged')
            // ls = ls ? JSON.parse(ls) : { items: [] }

            // let start = this.event.startTime.split(':')
            // start[0] = parseInt(start[0]) - parseInt(ls.timeOffset)
            // if (start[0] < 10) {
            //   start[0] = String(0) + String(start[0])
            // } else {
            //   start[0] = String(start[0])
            // }
            // let auxStartTime = start.join(':')
            // console.log('GMT +0 : ' + auxStartTime)
            // console.log(this.event.startTime)
            if (this.calendarconfirm) {
              this.changeToolSelected(null)
            } else {
              let data = {
                cardid: this.exhibitorCards[this.position],
                day: this.event.day,
                time_start: this.event.startTime + ':00',
                topic:
                  this.exhibitorCalendar.user.fullName +
                  ' ' +
                  this.exhibitorCalendar.user.business +
                  ' ' +
                  this.exhibitorCalendar.user.position,
              }
              this.addToMyCalendar(data)
              this.event = {}
            }
            break
          case 'deny':
            this.event = {}
            this.resetError({ calendar: null })
            break
        }
      },
      async changeCalendar(value) {
        this.position += value
        await this.getExhibitorCalendar(this.exhibitorCards[this.position])
      },
      checkEvents() {
        if (this.exhibitorCalendar.calendar) {
          let ls = JSON.parse(localStorage.getItem('loged'))
          let time_offset = 0
          if (ls.timeOffset) {
            time_offset = parseInt(ls.timeOffset)
          }

          let today = new Date()
          today.setHours(today.getHours() + -1 * time_offset)

          this.exhibitorCalendar.calendar.forEach((element) => {
            let eventDate = new Date(
              element.day + ' ' + element.startTime + ':00'
            )
            if (eventDate >= today) {
              this.find = true
            }
          })

          return !this.find
        }
      },
    },

    async created() {
      if (this.exhibitorCards) {
        await this.getExhibitorCalendar(this.exhibitorCards[0])
      }
    },
    beforeUnmount() {
      this.setCalendarConfirm(false)
    },
  }
</script>

<style scoped>
  .calendarmsgcont,
  .calendarcont {
    height: 100%;
  }

  .img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: bottom;
  }
  .img,
  .exhibitor,
  .business,
  .textinfo,
  .business p {
    display: inline-block;
    margin: 0 5px;
  }
  .info {
    text-align: left;
    padding: 5px 0px 5px 20px;
    display: flex; /* establish flex container */
    flex-direction: row; /* default value; can be omitted */
    flex-wrap: nowrap; /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */
    align-items: center;
  }
  .changeexhibitor {
    margin-right: 20px;
  }
  .calendarcont {
    position: relative;
  }
  .confirmcalendar {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .calendarmsg {
    border-width: 3px;
    border-style: solid;
    border-radius: 10px;
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-height: 30%;
    /* height: 100%; */
    overflow: hidden;
  }
  .buttonsform {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }
  .calendarmsg .buttonsform {
    position: initial;
  }
  .buttonsform button {
    width: auto;
  }
  .text {
    padding: 14px;
    font-size: 17px;
  }
  .datetime {
    font-weight: bold;
  }
  .errorcont {
    padding: 20px;
  }
  @media screen and (max-width: 1250px) {
    .calendarmsg {
      width: 40%;
    }
  }

  @media screen and (max-width: 800px) {
    .calendarmsg {
      width: 60%;
    }
    .exhibitor,
    .business {
      display: block;
    }
    .info {
      display: block;
      text-align: center;
      padding: 0;
      position: relative;
    }
    .textinfo {
      text-align: left;
    }

    .business,
    .changeexhibitor {
      margin: 0;
    }
    .img {
      /* display: block; */
      margin: 10px auto;
      height: 40px;
      width: 40px;
    }
    .exhibitor {
      font-size: 18px;
    }
    .changeexhibitor {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      text-align: left;
      display: flex; /* establish flex container */
      flex-direction: row; /* default value; can be omitted */
      flex-wrap: nowrap; /* default value; can be omitted */
      justify-content: space-between; /* switched from default (flex-start, see below) */
      align-items: center;
    }
  }
  @media screen and (max-width: 450px) {
    .calendarmsg {
      width: 80%;
    }
  }
</style>
