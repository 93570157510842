<template>
  <div class="news" @click="showMore()">
    <div class="imgcont contrast_bg_color main_color">
      <img :src="files + data.picture" :alt="data.fullName" />
    </div>
    <h3>{{ data.fullName }}</h3>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'NewsItem',
    props: {
      data: {
        type: Object,
        default: { id: '', fullName: '', description: '', picture: '' },
      },
    },
    computed: {
      ...mapState(['files']),
    },
    methods: {
      ...mapActions(['saveActualNews', 'changeToolSelected']),
      showMore() {
        this.saveActualNews(this.data)
        this.changeToolSelected(26)
      },
    },
  }
</script>

<style scoped>
  .news {
    width: 14%;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    text-align: center;
    height: 225px;
  }
  h3 {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }
  .imgcont {
    display: inline-block;
    position: relative;
    height: 140px;
    width: 80%;
    padding: 10px;
  }

  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  @media screen and (max-width: 1000px) {
    .news {
      width: 25%;
    }
  }
  @media screen and (max-width: 650px) {
    .news {
      width: 43%;
    }
  }
</style>
