<template>
  <div>
    <SearchFilters
      :data="data"
      :show="show"
      :filteredList="filteredList"
      :style="style"
      v-model="search"
      :disabled="disabled"
      @updateShow="updateShow"
      :year="year"
    />
    <List
      :filteredList="filteredList"
      :style="style"
      :type="type"
      :show="show"
      :dataType="type"
      @updateStyle="updateStyle"
    />
  </div>
</template>

<script>
  import SearchFilters from '@/components/SearchFilters.vue'
  import List from '@/components/List.vue'

  export default {
    name: 'SearchList',
    components: {
      SearchFilters,
      List,
    },
    data() {
      return {
        search: '',
        style: 1,
        show: false,
        disabled: [],
        year: {
          smallYear: null,
          bigYear: null,
        },
      }
    },
    props: {
      data: {
        type: Object,
        default: {},
      },
      type: {
        type: String,
        default: '',
      },
    },
    methods: {
      getYears() {
        if (
          !this.year.smallYear &&
          !this.year.bigYear &&
          this.data.filterOptions &&
          this.data.filterOptions.year
        ) {
          this.year.smallYear = this.data.filterOptions.year[0]
          if (this.data.filterOptions.year.length > 0) {
            this.year.bigYear = this.data.filterOptions.year[
              this.data.filterOptions.year.length - 1
            ]
          }
        }
      },
      updateShow() {
        this.show = !this.show
        if (this.show) {
          this.getYears()
        }
      },

      updateStyle(id) {
        this.style = id
      },
    },
    computed: {
      filteredList() {
        if (this.data.list) {
          if (this.data.list.products) {
            let result = this.data.list.products
            if (this.disabled.length > 0) {
              this.disabled.forEach((element) => {
                if (element.category) {
                  console.log(element.category)
                  result = result.filter(
                    (product) => product.category == element.category
                  )
                } else if (Object.keys(element)[0] == 'type') {
                  result = result.filter(
                    (product) => product.type != element.type
                  )
                } else if (Object.keys(element)[0] == 'operation') {
                  result = result.filter((product) => {
                    let find = false
                    for (let i = 0; i < product.operation.length; i++) {
                      if (product.operation[i] == element.operation) {
                        find = true
                      }
                    }

                    if (!find) {
                      return product
                    }
                  })
                }
              })
            }
            if (this.year.smallYear) {
              result = result.filter((product) => {
                if (
                  this.year.bigYear &&
                  this.year.bigYear >= product.market_entry &&
                  this.year.smallYear <= product.market_entry
                ) {
                  return product
                } else if (this.year.smallYear == product.market_entry) {
                  return product
                }
              })
            }
            return result.filter((product) => {
              return product.title
                .toLowerCase()
                .includes(this.search.toLowerCase())
            })
          } else if (this.data.list.exhibitors) {
            let result = this.data.list.exhibitors
            if (this.disabled.length > 0) {
              console.log(this.disabled)
              this.disabled.forEach((element) => {
                if (Object.keys(element)[0] == 'category') {
                  result = result.filter((exhibitor) => {
                    let find = false
                    for (let i = 0; i < exhibitor.categories.length; i++) {
                      if (exhibitor.categories[i].name != element.category) {
                        find = true
                      } else {
                        find = false
                      }
                    }
                    if (find) {
                      return exhibitor
                    }
                  })
                } else if (Object.keys(element)[0] == 'type') {
                  result = result.filter(
                    (exhibitor) => exhibitor.type != element.type
                  )
                } else if (Object.keys(element)[0] == 'operation') {
                  result = result.filter((exhibitor) => {
                    let find = true
                    for (let i = 0; i < exhibitor.operation.length; i++) {
                      if (exhibitor.operation[i] == element.operation) {
                        find = true
                      }
                    }

                    if (!find) {
                      return exhibitor
                    }
                  })
                }
              })
            }
            return result.filter((exhibitor) => {
              return exhibitor.title
                .toLowerCase()
                .includes(this.search.toLowerCase())
            })
          } else if (this.data.list.opportunities) {
            let result = this.data.list.opportunities
            if (this.disabled.length > 0) {
              this.disabled.forEach((element) => {
                if (Object.keys(element)[0] == 'type') {
                  result = result.filter(
                    (opportunity) => opportunity.subtype != element.type
                  )
                } else if (Object.keys(element)[0] == 'public_sector') {
                  result = result.filter(
                    (opportunity) =>
                      opportunity.public_sector != element.public_sector
                  )
                }
              })
            }
            return result.filter((opportunity) => {
              return opportunity.fullName
                .toLowerCase()
                .includes(this.search.toLowerCase())
            })
          } else if (this.data.list.news) {
            let result = this.data.list.news
            if (this.disabled.length > 0) {
              this.disabled.forEach((element) => {
                if (Object.keys(element)[0] == 'type') {
                  result = result.filter(
                    (newsItem) => newsItem.subtype != element.type
                  )
                } else if (Object.keys(element)[0] == 'source') {
                  result = result.filter(
                    (newsItem) => newsItem.source != element.source
                  )
                }
              })
            }
            return result.filter((newsItem) => {
              return newsItem.fullName
                .toLowerCase()
                .includes(this.search.toLowerCase())
            })
          } else if (this.data.list.trends) {
            let result = this.data.list.trends
            if (this.disabled.length > 0) {
              this.disabled.forEach((element) => {
                if (Object.keys(element)[0] == 'type') {
                  result = result.filter(
                    (trend) => trend.subtype != element.type
                  )
                } else if (Object.keys(element)[0] == 'source') {
                  result = result.filter(
                    (trend) => trend.source != element.source
                  )
                }
              })
            }
            return result.filter((trend) => {
              return trend.fullName
                .toLowerCase()
                .includes(this.search.toLowerCase())
            })
          }
        } else if (this.type == 'card' || this.type == 'cardexhibitor') {
          let result = this.data
          if (this.disabled.length > 0) {
            // this.disabled.forEach((element) => {
            //   if (Object.keys(element)[0] == 'type') {
            //     result = result.filter((trend) => trend.subtype != element.type)
            //   } else if (Object.keys(element)[0] == 'source') {
            //     result = result.filter(
            //       (trend) => trend.source != element.source
            //     )
            //   }
            // })
          }
          return result.filter((card) => {
            return (
              card.fullName.toLowerCase().includes(this.search.toLowerCase()) ||
              card.business.toLowerCase().includes(this.search.toLowerCase()) ||
              card.position.toLowerCase().includes(this.search.toLowerCase())
            )
          })
        } else if (this.type == 'favorite') {
          let result = this.data

          if (this.disabled.length > 0) {
            this.disabled.forEach((element) => {
              if (Object.keys(element)[0] == 'type') {
                result = result.filter((item) => item.type != element.type)
              }
            })
          }
          return result.filter((item) => {
            return (
              item.fullname.toLowerCase().includes(this.search.toLowerCase()) ||
              item.description.toLowerCase().includes(this.search.toLowerCase())
            )
          })
        }
      },
    },
  }
</script>
