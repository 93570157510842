<template>
  <div v-if="translations">
    <Title :text="translations.front_home_title" />
    <div class="homecont">
      <div
        class="companiescategories viewcontent"
        v-if="allData.categoriesComp"
      >
        <Category
          v-for="(category, index) in allData.categoriesComp.slice(0, 4)"
          :key="index"
          :category="category"
          :decoration="true"
          :limitList="7"
          :type="'home'"
          class="category"
          data-aos="zoom-in-up"
        />
      </div>
      <div
        v-if="allData.sections && allData.sections.length > 0"
        class="secundary_bg_color_transparent"
      >
        <div class="viewcontent">
          <h2 class="primary_color">
            {{ translations.front_home_features }}
          </h2>
          <Section
            v-for="(section, index) in allData.sections.slice(0, 4)"
            :key="index"
            :data="section"
            class="section"
            :index="index"
            data-aos="flip-up"
          />
        </div>
      </div>
      <div class="viewcontent messagewelcome ">
        <div class="fourth_color msg">
          <h2>
            {{ translations.front_home_digital }}
          </h2>
          <p>
            {{ translations.front_home_digital_description }}
          </p>
        </div>
      </div>
      <div
        class="sponsors"
        v-if="allData.sponsors && allData.sponsors.length > 0"
      >
        <Title :text="translations.front_title_sponsor" type="subtitle" />
        <div class="viewcontent">
          <Sponsors
            v-for="(sponsor, index) in allData.sponsors"
            :key="index"
            :data="sponsor"
            class="sponsorcont"
            data-aos="zoom-in-up"
          />
        </div>
      </div>

      <div
        class="trends sixth_bg_color_transparent grey_contrast_color"
        v-if="allData.presentations && isDisabled('trend')"
      >
        <div class="viewcontent ">
          <h2>
            {{ translations.front_home_title_trend }}
          </h2>
          <Trend
            v-for="(trend, index) in allData.presentations"
            :key="index"
            :index="index"
            :data="trend"
            :data-aos="index % 2 ? 'fade-right' : 'fade-left'"
            class="mouseHover"
          />
        </div>
      </div>
      <div
        class="viewcontent newscont"
        v-if="allData.news && isDisabled('news')"
      >
        <h2>{{ translations.front_home_title_news }}</h2>

        <NewsItem
          v-for="(news, index) in allData.news"
          :key="index"
          :data="news"
          data-aos="fade-up"
          data-aos-duration="3000"
          class="mouseHover"
        />
      </div>
    </div>
  </div>
</template>

<script>
  //https://michalsnik.github.io/aos/
  import Title from '@/components/Title.vue'
  import Category from '@/components/Category.vue'
  import Trend from '@/components/Trend.vue'
  import NewsItem from '@/components/NewsItem.vue'
  import Sponsors from '@/components/Sponsors.vue'
  import Section from '@/components/Section.vue'
  import AOS from 'aos'
  import 'aos/dist/aos.css'
  import { mapState } from 'vuex'
  export default {
    name: 'Home',
    components: {
      Title,
      Category,
      Trend,
      NewsItem,
      Sponsors,
      Section,
    },

    computed: {
      ...mapState(['allData', 'files', 'disabled_sections', 'translations']),
      checkLength() {
        let result = false
        if (this.data.categories.length > 5) {
          result = true
          this.numberlist = this.data.categories.length - 6
        }
        return result
      },
    },
    methods: {
      isDisabled(key) {
        return !this.disabled_sections.includes(key)
      },
    },
    created() {
      AOS.init()
    },
  }
</script>
<style scoped>
  .viewcontent {
    margin-bottom: 0;
  }
  .companiescategories {
    border-bottom-width: 1px;
    border-bottom-style: solid;

    margin-bottom: 18px;
    text-align: center;
  }

  .companiescategories .category {
    height: 450px;
    margin: 20px 10px;
  }
  .companiescategories .category,
  .section {
    width: 22%;
    vertical-align: top;
    display: inline-block;
  }
  .trends {
    padding: 20px 0;
  }

  .newscont {
    margin: 50px auto;
  }
  .messagewelcome,
  .sponsors {
    margin-bottom: 10px;
  }
  .sponsorcont {
    width: 45%;
    vertical-align: top;
    display: inline-block;
  }
  .messagewelcome p {
    width: 80%;
    margin: 0 auto;
  }
  .messagewelcome p,
  .messagewelcome span {
    display: inline-block;
    vertical-align: middle;
  }
  .messagewelcome span:last-of-type {
    transform: rotateY(180deg);
  }
  .viewcontent h2 {
    padding: 10px;
    text-transform: none;
  }
  /* .coments {
    margin: 20px 0;
    background-color: #d25252;
    padding: 30px 0 40px 0;
  }

  .rate {
    padding-left: 120px;
    color: yellow;
  }
  .img,
  .text,
  .text .svg,
  .text p {
    display: inline-block;
    vertical-align: top;
  }
  .text {
    color: #fff;
    width: 60%;
  }
  .text svg {
    font-size: 40px;
    margin-right: 20px;
  }
  .text svg:last-of-type {
    vertical-align: bottom;
  }
  .text p {
    width: 40%;
    padding: 20px;
    text-align: center;
  } 

   .imgcont {
    margin-right: 40px;
    display: inline-block;
    position: relative;
    height: 20px;
    width: 30px;
    background-color: #fff;
    padding: 30px;
    border-radius: 100%;
  }

  .imgcont img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: 60%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  } */

  .homecont {
    margin-bottom: 70px;
    overflow: hidden;
  }
  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    .companiescategories .category,
    .section {
      width: 46%;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 1000px) {
    .companiescategories .category,
    .section {
      width: 45%;
    }
  }
  @media screen and (max-width: 800px) {
    .sponsorcont {
      width: 90%;
    }
  }
  @media screen and (max-width: 500px) {
    .companiescategories .category,
    .section {
      width: 90%;
    }
  }
</style>
