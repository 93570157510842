<template>
  <div class="cardcont" v-if="cardsData.length > 0">
    <SearchList :data="cardsData" :type="defineType" :key="selected" />
    <div class="buttonsform contrast_bg_color">
      <button
        @click="removeSelected()"
        class="btntool fourth_bg_color contrast_color"
      >
        {{ translations.btn_close }}
      </button>
    </div>
  </div>
</template>

<script>
  import SearchList from '@/components/SearchList.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ToolCards',
    components: {
      SearchList,
    },
    props: {
      selected: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      ...mapActions(['changeToolSelected']),
      removeSelected() {
        this.changeToolSelected(null)
      },
    },
    computed: {
      ...mapState(['cardsData', 'translations']),
      defineType() {
        let type = ''
        if (this.selected == 21) {
          type = 'cardexhibitor'
        } else if (this.selected == 28) {
          type = 'card'
        }
        return type
      },
    },
  }
</script>

<style scoped>
  .cardcont {
    padding: 10px;
  }
</style>
