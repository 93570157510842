<template>
  <div class="calendarcont">
    <div class="calendarheader border_fourth">
      <div class="picker">
        <div class="daypicker">
          <button @click="moveCalendar(-1)">&lt;</button>
          <button @click="moveCalendar(1)">&gt;</button>
          <span>{{ title }}</span>
        </div>
        <div>
          <span style="left: 0"> Time zone : GMT {{ setTimeZone() }}:00</span>
        </div>
        <div class="stylepicker">
          <button @click="changeType()" class="calendarchange">
            <span v-if="type == 'week'">
              <i class="fas fa-calendar-day fa-2x"></i>
            </span>
            <span v-else-if="type == 'day'">
              <i class="fas fa-calendar-week fa-2x"></i>
            </span>
          </button>
        </div>
      </div>
      <div class="days">
        <div class="hour">
          {{ translations.time_calendar_hm }}
        </div>
        <div
          v-for="(day, index) in monthFormat"
          :key="index"
          class="border_fourth"
          :class="type"
        >
          <div>
            <span class="number fourth_color">{{ day.value }}</span>
            <span class="weekday">{{ defineWeekDay(index) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="calendar border_category"
      ref="calendarRef"
      :class="calendarUse"
    >
      <div
        class="houritem border_calendar"
        v-for="(hour, index) in generateHours()"
        :key="index"
        :style="{ height: `${rowSize}px` }"
      >
        <div class="hour" :style="{ height: `${rowSize}px` }">
          {{ hour }}
        </div>
        <div
          v-for="(day, index) in monthFormat"
          :key="index"
          class="border_fourth"
          :class="checkToday(day)"
          :style="{ height: `${rowSize}px` }"
        >
          <div
            v-if="checkEvent(yearMonth, day.value, hour)"
            class="event border_fourth sixth_bg_color contrast_color"
            :style="{
              height: `${rowSize *
                formatEvents(checkEvent(yearMonth, day.value, hour)) -
                10}px`,
            }"
            :class="
              type +
                'event ' +
                defineStatus(checkEvent(yearMonth, day.value, hour))
            "
            @click="msgEvent(checkEvent(yearMonth, day.value, hour))"
          >
            <div v-if="checkEvent(yearMonth, day.value, hour).title">
              <p
                class="eventtext a_color"
                :style="{ height: `${rowSize * 2 - 20}px` }"
              >
                {{ checkEvent(yearMonth, day.value, hour).title }}
              </p>
            </div>

            <!-- {{ consoleLof(event) }} -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  // https://www.vuescript.com/simple-vue-component-show-calendar-events/

  export default {
    name: 'Calendar',
    data() {
      return {
        type: 'week',
        title: '',
        today: new Date(),
        changeDate: new Date(),
        daysOfMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        monthFormat: {},
        yearMonth: '',
        week: 0,
        weekdays: [],
        day: 0,
        indexDay: 0,
        monthWeeks: 0,
        changeDay: 0,
        changeWeek: 0,
        changeMonth: 0,
        totalDaysOfMonth: 0,
        monthActual: 0,
        startWeekDay: 0,
        timeOffset: 0,
        months: [],
        days: [],
      }
    },
    props: {
      events: {
        type: Object,
        default: { id: '', title: '', startDate: '', endDate: '' },
      },
      rowSize: {
        type: Number,
        default: 80,
      },
      calendarUse: {
        type: String,
        default: 'myCal',
      },
    },
    emits: ['msgEvent'],

    computed: {
      ...mapState(['logged', 'translations']),
      setMenuType() {
        return this.window.width <= 900
      },
      checkGenerate() {
        let option = 0
        if (this.logged) {
          let ls = localStorage.getItem('loged')
          ls = ls ? JSON.parse(ls) : {}
          if (ls.option) {
            option = parseInt(ls.option)
          }
        }
        let options = [0, 2, 3]

        return (
          this.calendarUse == 'myCal' && options.includes(option) && this.logged
        )
      },
    },
    methods: {
      formatEvents(data) {
        let start = data.startTime.split(':')
        let minutes_start = parseInt(start[0]) * 60 + parseInt(start[1])
        let end = data.endTime.split(':')
        let minutes_end = parseInt(end[0]) * 60 + parseInt(end[1])
        let height = 0
        switch (minutes_end - minutes_start) {
          case 30:
            height = 1
            break
          case 60:
            height = 2
            break
        }

        return height
      },
      setTimeZone() {
        var ls = localStorage.getItem('loged')
        ls = ls ? JSON.parse(ls) : { items: [] }
        return ls.timeOffset >= 0 ? '+' + ls.timeOffset : ls.timeOffset
      },
      // timeZoneAply() {
      //   console.log('-----------------')
      //   console.log(this.events)
      //   var ls = localStorage.getItem('loged')
      //   ls = ls ? JSON.parse(ls) : { items: [] }
      //   console.log(ls.timeOffset)
      //   this.events.forEach((element) => {
      //     let start = element.startTime.split(':')
      //     let end = element.endTime.split(':')
      //     start[0] = parseInt(start[0]) + parseInt(ls.timeOffset)
      //     end[0] = parseInt(end[0]) + parseInt(ls.timeOffset)
      //     if (start[0] < 10) {
      //       start[0] = String(0) + String(start[0])
      //     } else {
      //       start[0] = String(start[0])
      //     }

      //     if (end[0] < 10) {
      //       end[0] = String(0) + String(end[0])
      //     } else {
      //       end[0] = String(end[0])
      //     }
      //     element.startTime = start.join(':')
      //     element.endTime = end.join(':')
      //   })
      //   console.log(this.events)
      //   console.log('@@@@@@@@@@@@@@@')
      // },

      changeFormatEvents() {
        this.events.forEach((element) => {
          let auxDate = new Date(element.day)
          let auxHour = element.startTime.split(':')
          if (parseInt(auxHour[0]) >= 24) {
            if (auxDate.getDate() + 1 > this.daysOfMonth[auxDate.getMonth()]) {
              // console.log('month previus : ' + auxDate.getMonth())
              // console.log(
              //   'days of month previus : ' +
              //     this.daysOfMonth[auxDate.getMonth()]
              // )
              // console.log('date + 1: ' + auxDate.getDate())
            } else {
              auxDate.setDate(auxDate.getDate() + 1)
              auxHour[0] = parseInt(auxHour[0])
              // console.log('month previus : ' + auxDate.getMonth())
              // console.log(
              //   'days of month previus : ' +
              //     this.daysOfMonth[auxDate.getMonth()]
              // )
              // console.log('date + 1: ' + auxDate.getDate())
            }
          }
          console.log(auxDate)
        })
      },
      defineStatus(event) {
        if (event.day) {
          let clas = ''
          if (event.status == 1 || !event.id) {
            clas = 'calendar_bg_available'
          } else if (event.status == 2) {
            clas = 'calendar_bg_notavailable'
          }
          return clas
        }
      },
      scrollToEnd() {
        let container = this.$refs.calendarRef
        container.scrollTop = container.scrollHeight / 3
      },
      defineWeekDay(index) {
        if (this.type == 'day') {
          index = this.indexDay - 1
        }
        return this.days[index]
      },
      msgEvent(event) {
        this.$emit('msgEvent', event)
      },
      defineTitle(year, month, date) {
        let text = ''
        switch (this.type) {
          case 'day':
            text = date + ' ' + this.months[month] + ' ' + year
            break
          case 'week':
            text = this.months[month] + ' ' + year
            break
        }
        this.title = text
      },
      async calendarStart() {
        var month = this.changeDate.getUTCMonth() + this.changeMonth
        var day = this.changeDate.getUTCDay()
        var year = this.changeDate.getUTCFullYear()
        var date = this.changeDate.getUTCDate()
        // this.changeDay = this.changeDate.getUTCDay() + 1

        if (month >= 12) {
          year = year + parseInt(month / 12)
          month = month % 12
        } else if (month == -1) {
          month = Math.abs(month)
          // console.log(parseInt(month - 1 / 12) + 1)
          year = year - (parseInt((month - 1) / 12) + 1)
          month = 12 - (month % 12)
        } else if (month < 0) {
          month = Math.abs(month)
          // console.log(parseInt(month / 12) + 1)
          year = year - (parseInt(month / 12) + 1)
          month = 12 - (month % 12)
          month--
        }
        var totalDaysOfMonth = this.daysOfMonth[month]
        var dateToHighlight = 0
        if (this.day == 0) {
          this.day = date
        }

        if (month == 0) {
          var negativeDays = this.daysOfMonth[11]
        } else {
          negativeDays = this.daysOfMonth[month - 1]
        }
        // console.log(negativeDays)

        // console.log(month)
        // console.log(day)
        // Determine if Month && Year are current for Date Highlight
        // console.log(month)
        // console.log('days of month : ' + totalDaysOfMonth)
        if (
          this.today.getUTCMonth() === month &&
          this.today.getUTCFullYear() === year
        ) {
          dateToHighlight = date
        }

        //Getting February Days Including The Leap Year
        if (month === 1) {
          if ((year % 100 !== 0 && year % 4 === 0) || year % 400 === 0) {
            totalDaysOfMonth = 29
          }
        }
        this.totalDaysOfMonth = totalDaysOfMonth
        // Get Start Day
        let i = 1
        let monthDays = []
        // console.log(year)
        // console.log(month)
        // console.log(totalDaysOfMonth)
        // console.log(this.changeMonth)
        if (this.changeMonth > 0) {
          let newDate = new Date(
            year + '-' + (month + 1) + '-' + totalDaysOfMonth
          )
          // console.log('date : ' + newDate)
          day = newDate.getUTCDay()
          date = newDate.getUTCDate()
        } else if (this.changeMonth < 0) {
          let newDate = new Date(
            year + '-' + (month + 1) + '-' + totalDaysOfMonth
          )
          // console.log('date : ' + newDate)
          day = newDate.getUTCDay()
          date = newDate.getUTCDate()
        }

        let start = this.getCalendarStart(day, date)
        // console.log('start : ' + start)
        // let finish = this.getCalendarStop(day, date)
        //GENERATE MONTH DAYS

        while (i <= totalDaysOfMonth) {
          monthDays.push({ value: i })
          i++
        }
        i = 0
        if (this.type == 'week') {
          while (i < start) {
            monthDays.unshift({ value: negativeDays })
            negativeDays--
            i++
          }
        } else if (this.type == 'day') {
          while (i < start) {
            monthDays.unshift({ value: null })
            negativeDays--
            i++
          }
        }

        i = 0
        let monthFormated = []

        // console.log('month actual :' + this.monthActual)
        let contador = 0
        // console.log('dateToHighlight :' + dateToHighlight)
        monthDays.forEach((element, key) => {
          if (element.value == dateToHighlight) {
            monthFormated.push({
              value: element.value,
              today: true,
              week: contador,
            })
            // this.week = contador + this.changeWeek
            this.startWeekDay = contador
            this.week = contador + this.monthActual
            // console.log(contador)
            // console.log('###############')
            // console.log('-----------------')
            // console.log('contador : ' + contador)
            // console.log('changeWeek : ' + this.changeWeek)
            // this.changeWeek = contador + this.changeWeek
            // console.log('changeWeek : ' + this.changeWeek)
            // this.day = dateToHighlight + this.changeDay
          } else {
            // console.log(contador)
            monthFormated.push({ value: element.value, week: contador })
          }
          // console.log(key)
          if ((key + 1) % 7 == 0) {
            contador++
          }

          i++
        })

        // console.log('@@@@@@@@@@@@@@@@@@@@@@@@')
        // console.log('this.week  : ' + this.week)
        // if (this.changeMonth < 0) {
        //   contador++
        // }
        // console.log('numero de dias: ' + contador * 7)
        if (this.type == 'week') {
          monthFormated = monthFormated.slice(0, contador * 7)

          monthDays = monthDays.slice(0, contador * 7)
          // if (
          //   this.changeMonth == 0 &&
          //   this.week > this.changeMonth &&
          //   this.week != contador
          // ) {
          //   console.log('this.changeMonth  : ' + this.changeMonth)
          //   this.changeWeek = this.week
          // }
        }
        // console.log('monthFormated')
        // console.log(monthFormated)
        // console.log('@@@@@@@@@@@@@@@@@@@@@@@@')
        // console.log('months')
        // console.log(monthDays)
        // console.log('contador : ' + contador)
        // console.log('week : ' + week)

        if (this.changeMonth <= 0) {
          this.monthWeeks = contador - 1
          // this.monthFormat = monthFormated
          this.yearMonth = year + '-' + ('0' + (month + 1)).slice(-2)
          // console.log('yearMonth : ' + this.yearMonth)
        } else {
          this.monthWeeks = contador - 1
          this.yearMonth = year + '-' + ('0' + (month + 1)).slice(-2)
        }
        let dayTitle = 0
        // console.log('this week : ' + this.week)
        // console.log('this changeweek : ' + this.changeWeek)
        if (this.week == 'max' || this.changeWeek == -1) {
          this.week = Math.max.apply(
            Math,
            monthFormated.map(function(o) {
              return o.week
            })
          )
          this.changeWeek = this.week
        }
        if (this.changeWeek > this.week) {
          this.changeWeek = 0
        }
        // console.log('this.changeMonth : ' + this.changeMonth)
        // console.log('this.week  : ' + this.week)
        // console.log('this.changeWeek  : ' + this.changeWeek)
        // console.log('contador  : ' + contador)
        // console.log('-----------------')

        //GENERATE DAY OR WEEK VIEW
        if (this.type == 'day') {
          // console.log('this.changeDay : ' + this.changeDay)
          // console.log('this.day  : ' + this.day)
          // console.log('this.changeMonth : ' + this.changeMonth)
          if (this.changeDay == 'max') {
            this.changeDay = totalDaysOfMonth
          }
          // console.log('this.changeDay : ' + this.changeDay)
          this.monthFormat = monthFormated.filter((day) => {
            if (this.changeMonth != 0) {
              dayTitle = this.changeDay
              return day.value == this.changeDay
            } else {
              dayTitle = this.day + this.changeDay
              return day.value == this.day + this.changeDay
            }
          })
          // console.log('this.monthFormat : ')
          // console.log(this.monthFormat)

          let week = monthFormated.filter(
            (day) => day.week == this.monthFormat[0].week
          )
          let i = 1
          week.forEach((element) => {
            if (element.value == this.monthFormat[0].value) {
              this.indexDay = i
            }
            i++
          })
        } else if (this.type == 'week') {
          this.weekdays = []
          // console.log('/*----*/')
          // console.log(monthFormated)
          // console.log('#############')
          // console.log('changeWeek: ' + this.changeWeek)
          // console.log('#############')
          // console.log(monthFormated)
          // console.log('this week : ' + this.week)
          this.monthFormat = monthFormated.filter((day) => {
            // console.log('DAY WEEK : ' + day.week)
            if (this.changeMonth == 0) {
              if (day.week == this.week) {
                this.weekdays.push(day)
                // console.log(day)
              }

              return day.week == this.week
            } else {
              if (day.week == this.changeWeek) {
                this.weekdays.push(day)
                // console.log(day)
              }

              return day.week == this.changeWeek
            }
          })

          // console.log('/*----*/')
        }

        // console.log('this.monthFormat')
        // console.log(this.monthFormat)
        if (this.monthFormat.length < 7 && this.type == 'week') {
          if (this.week == 4) {
            this.moveCalendar(1)
          } else {
            do {
              this.monthFormat.push({
                value: null,
                week: this.monthFormat[0].week,
              })
            } while (this.monthFormat.length < 7)
          }
        }

        await this.defineTitle(year, month, dayTitle)
        this.scrollToEnd()
      },
      getCalendarStart(dayOfWeek, currentDate) {
        var date = currentDate - 1
        var startOffset = (date % 7) - dayOfWeek
        if (startOffset > 0) {
          startOffset -= 7
        }
        return Math.abs(startOffset)
      },
      generateHours() {
        var x = 30 //minutes interval
        var times = [] // time array
        var tt = 0 // start time

        for (var i = 0; tt < 24 * 60; i++) {
          var hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
          var mm = tt % 60 // getting minutes of the hour in 0-55 format
          times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2)
          tt = tt + x
        }
        return times
      },
      moveCalendar(value) {
        switch (this.type) {
          case 'day':
            this.changeDay += value

            //actual month
            if (this.changeMonth == 0) {
              if (this.day + this.changeDay > this.totalDaysOfMonth) {
                this.changeMonth++
                this.day = 1
                this.changeDay = 1
              } else {
                if (this.changeMonth <= 0) {
                  if (this.day + this.changeDay <= 0) {
                    this.changeMonth--
                    this.changeDay = 'max'
                    this.day = 1
                  }
                }
              }
            } else {
              //other months
              if (this.day + this.changeDay > this.totalDaysOfMonth + 1) {
                this.changeMonth++
                if (this.changeMonth == 0) {
                  this.day = 1
                  this.changeDay = 0
                } else {
                  this.day = 1
                  this.changeDay = 1
                }
              } else if (this.changeDay <= 0) {
                this.changeMonth--
                this.changeDay = 'max'
                this.day = 1
              }
            }

            if (this.day == this.totalDaysOfMonth) {
              this.changeMonth++
              this.day = 1
              this.changeDay = 1
            }

            break
          case 'week':
            // console.log('week :' + (+this.week + +this.changeWeek))
            // console.log('---------------')
            // console.log('week: ' + this.week)
            // console.log('changeWeek: ' + this.changeWeek)
            // console.log('---------------')

            // MONTH  monthactual for actual month uses only, later its managed by this.week and this.changeWeek
            if (this.changeMonth == 0) {
              this.monthActual += value

              if (this.startWeekDay + this.monthActual > this.monthWeeks) {
                this.changeMonth++
                this.week = 0
                this.changeWeek = 0
              } else if (this.startWeekDay + this.monthActual < 0) {
                this.changeMonth--
                this.week = 'max'
                this.changeWeek = -1
              }
            } else {
              this.changeWeek += value
              this.week = this.changeWeek

              if (this.changeMonth == 1 && this.changeWeek < 0) {
                this.changeMonth--
                this.week = 'max'
                this.changeWeek = -1
                this.monthActual--
              } else if (
                this.changeMonth == -1 &&
                this.changeWeek > this.monthWeeks
              ) {
                this.monthActual++
                this.changeMonth++
                this.week = 0
                this.changeWeek = 0
              } else {
                if (this.changeWeek > this.monthWeeks) {
                  this.changeMonth++
                  this.week = 0
                  this.changeWeek = 0
                } else if (this.changeWeek < 0) {
                  this.changeMonth--
                  this.week = 'max'
                  this.changeWeek = -1
                }
              }
            }

            //
            break
        }

        this.calendarStart()
      },
      checkToday(day) {
        let clas
        switch (this.type) {
          case 'day':
            clas = 'day'
            break
          case 'week':
            clas = 'week'
            break
        }
        var today = this.today.getUTCDate()

        if (day.value != today) {
          clas += ' calendar_bg_today'
        }
        return clas
      },

      checkEvent(yearMonth, day, hour) {
        if (this.events && yearMonth && day && hour) {
          // let auxDate = new Date(day)

          // let num = this.daysOfMonth[auxDate.getMonth()]

          let event = []
          event.push(
            this.events.filter((event) => {
              let formatedYearMonth = yearMonth
              if (day >= 28) {
                let testData = yearMonth.split('-')
                formatedYearMonth =
                  parseInt(testData[0]) +
                  '-' +
                  ('0' + (parseInt(testData[1]) - 1)).slice(-2)
              }

              return (
                event.day + ' ' + event.startTime + ':00' ==
                formatedYearMonth +
                  '-' +
                  ('0' + day).slice(-2) +
                  ' ' +
                  hour +
                  ':00'
              )
            })
          )

          let result = event[0][0]
          // console.log(event)
          if (result) {
            return result
          }
        }
      },
      changeType() {
        if (this.type == 'week') {
          this.type = 'day'
        } else {
          this.type = 'week'
        }
      },
      addTranslations() {
        if (this.translations) {
          this.months = [
            this.translations.time_calendar_jan,
            this.translations.time_calendar_feb,
            this.translations.time_calendar_mar,
            this.translations.time_calendar_apr,
            this.translations.time_calendar_may,
            this.translations.time_calendar_jun,
            this.translations.time_calendar_jul,
            this.translations.time_calendar_aug,
            this.translations.time_calendar_sep,
            this.translations.time_calendar_oct,
            this.translations.time_calendar_nov,
            this.translations.time_calendar_dec,
          ]
          this.days = [
            this.translations.time_calendar_sun,
            this.translations.time_calendar_mon,
            this.translations.time_calendar_tue,
            this.translations.time_calendar_wed,
            this.translations.time_calendar_thu,
            this.translations.time_calendar_fri,
            this.translations.time_calendar_sat,
          ]
        }
      },
    },

    // async beforeMount() {
    // this.setTimeZone()

    // },

    async created() {
      await this.calendarStart()
      this.addTranslations()

      // console.log(this.events)
    },
    watch: {
      type(to, from) {
        this.calendarStart()
      },
    },
  }
</script>

<style scoped>
  .days .day,
  .days .week,
  .hour,
  .houritem .day,
  .houritem .week {
    display: inline-block;
  }
  .days .week,
  .houritem .week {
    vertical-align: top;
    width: 12%;
    height: 55px;
  }
  .days .day,
  .houritem .day {
    width: 78%;
  }
  .hour {
    width: 45px;
    text-align: left;
    vertical-align: bottom;
  }
  .days .day,
  .days .week,
  .houritem .day,
  .houritem .week {
    border-left-width: 1px;
    border-left-style: solid;
  }
  .days .day,
  .houritem .day {
    border-right-width: 1px;
    border-right-style: solid;
  }
  .calendarheader,
  .calendar,
  .houritem {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .picker {
    text-align: left;
    display: flex; /* establish flex container */
    flex-direction: row; /* default value; can be omitted */
    flex-wrap: nowrap; /* default value; can be omitted */
    justify-content: space-between; /* switched from default (flex-start, see below) */
    align-items: center;
  }
  .stylepicker,
  .daypicker {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .calendarchange {
    font-size: 15px;
  }
  .weekday,
  .number {
    display: block;
  }
  .number {
    font-size: 20px;
    font-weight: bold;
  }
  .calendar {
    overflow-y: scroll;
    height: 400px;
    position: relative;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .calendar::-webkit-scrollbar {
    display: none;
  }

  .houritem .hour,
  .houritem .week,
  .houritem .day,
  .houritem {
    height: 80px;
  }
  .houritem .hour {
    vertical-align: top;
  }
  .calendarcont {
    overflow: hidden;
    position: relative;
  }
  .houritem {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .event {
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-size: 15px;
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;
    width: 90%;
    margin: 0 auto;
    margin-top: 3px;
    padding: 2px;
    position: relative;
  }

  .generate {
    font-size: 14px;
    width: 85px;
    padding: 0;
    height: auto;
  }
  .eventtext {
    /* position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transform: translateY(-50%); */
    vertical-align: middle;
    font-size: 12px;
  }

  @media screen and (max-width: 920px) {
    .week .eventtext {
      position: relative;
      transform: none;
    }
  }
  @media screen and (max-width: 800px) {
    .calendar {
      height: 620px;
    }
    .event {
      padding: 0;
    }
    .calendarcont,
    .number {
      font-size: 14px;
    }
    .days .week,
    .houritem .week {
      height: 42px;
    }
  }
  @media screen and (max-width: 650px) {
    .generate {
      font-size: 10px;
      width: 72px;
    }
    .calendar {
      height: 450px;
    }
  }
  @media screen and (max-width: 400px) {
    .calendar {
      height: 400px;
    }
    .hour {
      width: 40px;
    }
    .exhibitorCal {
      height: 370px;
    }
  }
</style>
