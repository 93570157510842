<template>
  <div class="errorcont">
    <div class="message error apierror_border border_tenth contrast_bg_color">
      <div class="cont">
        <img :src="files + '/property/' + biglogo" class="errorlogo" />
        <Title :text="checkTitle" :align="'middle'" />
        <p class="main_color text">{{ checkValue }}</p>
        <button
          class="btn primary_bg_color contrast_color"
          @click="toRegist()"
          v-if="showRegist"
          type="button"
        >
          {{ translations.btn_register }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ApiError',
    components: {
      Title,
    },
    computed: {
      ...mapState(['errorApi', 'files', 'biglogo', 'translations']),
      checkValue() {
        let result = this.translations.error_api
        if (typeof this.errorApi === 'string') {
          result = this.errorApi
        } else if (this.errorApi.date_start && this.errorApi.date_end) {
          result = this.translations.error_exhibition_start
          result = result.replace('{var0}', this.errorApi.date_start)
          result = result.replace('{var1}', this.errorApi.date_end)
        }
        return result
      },
      showRegist() {
        return this.errorApi.date_start && this.errorApi.date_end
      },
      checkTitle() {
        let result = this.translations.title_error_api
        if (typeof this.errorApi === 'string') {
          result = this.errorApi
        } else if (this.errorApi.date_start && this.errorApi.date_end) {
          result = this.translations.title_error_exhibition_start
        }
        return result
      },
    },
    methods: {
      ...mapActions(['changeToolSelected']),
      toRegist() {
        this.changeToolSelected(1)
      },
    },
  }
</script>

<style scoped>
  .errorcont {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
  }
  .text {
    margin-top: 35px;
    font-size: 18px;
  }
  .error {
    border-width: 3px;
    border-style: solid;
    border-radius: 10px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 950px;
    height: 90%;
  }
  .cont {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }
  .apierror_border {
    border-width: 1px;
    border-style: solid;
  }
  .errorlogo {
    max-height: 220px;
  }

  /* @media screen and (max-width: 1100px) {
    .error {
      width: 40%;
    }
  }
  @media screen and (max-width: 500px) {
    .error {
      width: 70%;
    }
  } */
</style>
