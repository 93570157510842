import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

const INIT = store.dispatch('setConf')

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: Home
  },
  {
    path: '/createpassword/:action/:token',
    name: 'Creating password user',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/ValidateMail.vue')
  },
  {
    path: '/exhibitors',
    name: 'Exhibitors & products',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (!store.state.privateplatform) {
          next()
        } else {
          next('/')
        }
      })
    },
    component: () => import('../views/Exhibitors.vue')
  }, {
    path: '/products',
    name: 'Private products',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (store.state.privateplatform) {
          next()
        } else {
          next('/')
        }
      })
    },
    component: () => import('../views/Products.vue')
  },
  {
    path: '/exhibitor/:id',
    name: 'Exhibitor',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/Exhibitor.vue')
  },
  {
    path: '/exhibitorlist',
    name: 'ExhibitorList',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/ExhibitorList.vue')
  },
  {
    path: '/productlist',
    name: 'ProductList',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/ProductList.vue')
  },
  {
    path: '/product/:id',
    name: 'Product',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/Product.vue')
  }, {
    path: '/recoverPassword/:id',
    name: 'RecoverPassword',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/RecoverPassword.vue')
  },
  {
    path: '/presentations',
    name: 'Industry trends',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (!store.state.disabled_sections.includes('trend')) {
          next()
        } else {
          next('/')
        }
      })
    },
    component: () => import('../views/Presentations.vue')
  }, {
    path: '/presentations/:id',
    name: 'Trend',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (!store.state.disabled_sections.includes('trend')) {
          next()
        } else {
          next('/')
        }
      })
    },
    component: () => import('../views/Trend.vue')
  },
  {
    path: '/investment',
    name: 'Investment Opportunities',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (!store.state.disabled_sections.includes('opportunity')) {
          next()
        } else {
          next('/')
        }
      }
      )
    },
    component: () => import('../views/Investment.vue')
  },
  {
    path: '/investment/:id',
    name: 'Opportunity',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (!store.state.disabled_sections.includes('opportunity')) {
          next()
        } else {
          next('/')
        }
      })
    },
    component: () => import('../views/Opportunity.vue')
  },
  {
    path: '/news',
    name: 'Newsroom',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        if (!store.state.disabled_sections.includes('news')) {
          next()
        } else {
          next('/')
        }
      })
    },
    component: () => import('../views/News.vue')
  }, {
    path: '/call/:id',
    name: 'Call',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/Call.vue')
  }, {
    path: '/paytest',
    name: 'PayTest',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/PayTest.vue')
  }, {
    path: '/paysuccess',
    name: 'PaySuccess',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/PaySuccess.vue')
  }, {
    path: '/payerror',
    name: 'PayError',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/PayError.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    beforeEnter: (to, from, next) => {
      INIT.then(function () {
        next()
      })
    },
    component: () => import('../views/NotFound.vue')
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
