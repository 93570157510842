<template>
  <div class="flags">
    <div class="flagContainer">
      <button
        v-for="(language, index) in checkLanguages"
        :key="index"
        @click="select(language.id)"
        :class="language.id"
        class="flag"
      >
        <span
          class="value"
          :class="isSelected(language.id) ? 'primary_color' : 'a_color'"
          >{{ language.value }}</span
        >
        <!-- <img
          :src="language.icon"
          :style="{
            filter: isSelected(language.id) ? 'none' : 'grayscale(100%)',
          }"
        /> -->
      </button>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  // https://github.com/LeCoupa/vueflags/tree/master/flags
  export default {
    name: 'Flags',

    data() {
      return {
        arrayLanguages: [
          // { id: 'es', icon: require('@/assets/flags/es.svg') },
          {
            id: 'en',
            icon: require('@/assets/flags/en.svg'),
            value: 'English',
          },
          {
            id: 'es',
            icon: require('@/assets/flags/es.svg'),
            value: 'Español',
          },
          { id: 'zh', icon: require('@/assets/flags/cn.svg'), value: '中文' },
        ],
      }
    },
    methods: {
      ...mapActions(['selectLanguage']),
      select(id) {
        this.selectLanguage(id)
      },
      isSelected(id) {
        if (this.languageSelected == id) {
          this.$i18n.locale = this.languageSelected
          return true
        } else {
          return false
        }
      },
    },
    computed: {
      ...mapState(['languages', 'languageSelected']),
      checkLanguages() {
        return this.arrayLanguages.filter((item) =>
          this.languages.includes(item.id)
        )
      },
    },
    watch: {
      languageSelected(to, from) {
        // this.$router.go()
        window.location.reload()
      },
    },
  }
</script>

<style scoped>
  .flags,
  .flagContainer {
    display: inline-block;
  }
  .flags {
    /* float: right; */
    padding-right: 5px;
  }
  img {
    height: 20px;
    padding-top: 5%;
  }
  .flag {
    height: auto;
  }
  .value {
    font-size: 12px;
    vertical-align: middle;
  }
</style>
