<template>
  <div
    class="miniformcont  black_bg_transparent"
    @click.self="removeSelected()"
  >
    <div class="miniform border_tenth contrast_bg_color">
      <h3 class="primary_color tenth_bg_color">{{ titleSelected }}</h3>
      <div class="toolcont">
        <div v-if="selected == 0" class="cont">
          <ToolLogin />
        </div>
        <div v-else-if="selected == 1" class="toolregist">
          <ToolRegister />
        </div>
        <div v-else-if="selected == 10" class="toolinfo">
          <ToolMyAccount />
        </div>
        <div v-else-if="selected == 11" class="toolinfo">
          <ToolMyBriefcase />
        </div>
        <div v-else-if="selected == 12" :class="chatEnabled">
          <ToolChat />
        </div>
        <div v-else-if="selected == 13">
          <ToolMyCalendar />
        </div>

        <div v-else-if="selected == 21">
          <ToolCards :selected="selected" />
        </div>
        <div v-else-if="selected == 24" class="toolinfo">
          <ToolCalendar />
        </div>

        <div v-else-if="selected == 26" class="toolinfo">
          <ToolNews />
        </div>
        <div v-else-if="selected == 28">
          <ToolCards :selected="selected" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ToolLogin from '@/components/ToolLogin.vue'
  import ToolRegister from '@/components/ToolRegister.vue'
  // import ToolShare from '@/components/ToolShare.vue'
  import ToolMyBriefcase from '@/components/ToolMyBriefcase.vue'
  import ToolMyAccount from '@/components/ToolMyAccount.vue'
  import ToolChat from '@/components/ToolChat.vue'
  import ToolCards from '@/components/ToolCards.vue'
  import ToolCalendar from '@/components/ToolCalendar.vue'
  // import ToolNotifications from '@/components/ToolNotifications.vue'
  import ToolMyCalendar from '@/components/ToolMyCalendar.vue'
  import ToolNews from '@/components/ToolNews.vue'
  import { mapActions, mapState } from 'vuex'
  export default {
    components: {
      ToolLogin,
      ToolRegister,
      // ToolShare,
      ToolMyBriefcase,
      ToolMyAccount,
      ToolChat,
      ToolCards,
      ToolCalendar,
      ToolMyCalendar,
      // ToolNotifications,
      ToolNews,
    },
    props: {
      selected: {
        type: Number,
        default: 0,
      },
    },

    methods: {
      ...mapActions(['changeToolSelected']),
      removeSelected() {
        this.changeToolSelected(null)
      },
    },
    computed: {
      ...mapState(['errors', 'translations']),
      chatEnabled() {
        return this.errors.chat ? 'cont' : ''
      },
      titleSelected() {
        let value = ''
        switch (this.selected) {
          case 0:
            value = this.translations.title_popup_login
            break
          case 1:
            value = this.translations.title_popup_regist
            break
          case 10:
            value = this.translations.title_popup_account
            break
          case 11:
            value = this.translations.title_popup_briefcase
            break
          case 12:
            value = this.translations.title_popup_chat
            break
          case 13:
            value = this.translations.title_popup_mycalendar
            break
          case 21:
            value = this.translations.title_popup_pick_card
            break
          case 24:
            value = this.translations.title_popup_video_meeting
            break

          case 26:
            value = this.translations.title_popup_news
            break
          case 28:
            value = this.translations.title_popup_exhibitorchat
            break
        }
        return value
      },
    },
  }
</script>

<style scoped>
  .toolcont {
    height: 88%;
    overflow-y: scroll;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .toolinfo {
    height: 100%;
  }

  .toolcont::-webkit-scrollbar {
    display: none;
  }
  .toolregist {
    height: 100%;
  }
  .miniformcont {
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
  }
  .miniform {
    border-width: 3px;
    border-style: solid;
    border-radius: 10px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 90%;
    /* max-height: 950px; */
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .miniform::-webkit-scrollbar {
    display: none;
  }
  .miniform h3 {
    margin: 0px;
  }

  .cont {
    position: relative;
    top: 40%;
    height: 100%;
    transform: translateY(-40%);
    -webkit-transform: translateY(-40%);
  }
</style>
