<template>
  <div class="contactcont hi" :class="isSelected">
    <!-- @click="$emit('getContact', contact.token)" -->
    <div class="chatimg">
      <div
        class="img contrast_bg_color"
        :style="`background-image: url(${files + contact.picture})`"
      ></div>
    </div>
    <div class="chatname">
      <p class="name">
        {{ contact.fullName }}
        <span v-if="number > 0" class="number primary_bg_color contrast_color"
          >{{ number }}
        </span>
      </p>
      <span class="business" v-if="contact.business">
        {{ contact.business }}
      </span>
    </div>
    <div class="icons">
      <button
        type="button"
        v-on:click.stop
        @click="downloadFile()"
        :disabled="disabled"
      >
        <i class="fas fa-download"></i>
      </button>
      <!-- <button
        type="button"
        v-on:click.stop
        @click="removeConversation()"
        :disabled="disabled"
      >
        <i class="far fa-trash-alt"></i>
      </button> -->
      <!-- {{ contact }} -->
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ChatContact',
    data() {
      return {
        disabled: false,
      }
    },
    props: {
      contact: {
        type: Object,
        default: { fullName: '', business: '', img: '' },
      },
      selected: {
        type: String,
        default: '',
      },
    },
    // emits: ['getContact'],

    computed: {
      ...mapState(['chat', 'files']),
      number() {
        if (localStorage.getItem('chat') && localStorage.getItem('chatback')) {
          let chatlistback = JSON.parse(localStorage.getItem('chatback'))
          let chatlist = JSON.parse(localStorage.getItem('chat'))

          let nummessages = 0
          let nummessagesback = 0
          for (var i in chatlistback.chats) {
            if (chatlistback.chats[i].code == this.contact.code) {
              nummessagesback = chatlistback.chats[i].n_message
              break
            }
          }
          for (var i in chatlist.chats) {
            if (chatlist.chats[i].code == this.contact.code) {
              nummessages = chatlist.chats[i].n_message
              break
            }
          }
          return nummessagesback - nummessages
        }
      },
      isSelected() {
        let clas = ''
        if (this.selected && this.contact.tchat == this.selected) {
          clas = 'nineth_bg_color'
        } else if (this.number > 0) {
          clas = 'eleventh_bg_color contrast_color'
        }
        return clas
      },
    },
    methods: {
      ...mapActions(['getContacts', 'getChat']),
      removeConversation() {},
      async downloadFile() {
        // this.downloadChat(this.chatSelected)
        // this.forceFileDownload('hola')
        await this.getContacts()
        // console.log(this.chat.contacts)
        let fileHTML =
          '<html><head><title>Chat logs</title><style>.bg-green {background-color: #64ff9121;} .img { height: 50px; width: 50px; border-radius: 100%; background-repeat: no-repeat; background-position: top; background-size: cover; margin: 0 auto; }</style> <meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"><link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"></head><body><div class="container my-2">'
        // console.log(this.chat.messages)
        fileHTML +=
          '<div class="col-6 d-inline-block my-3"><div class="chatimg d-inline-block align-middle"><div class="img contrast_bg_color" style="background-image: url(' +
          this.files +
          this.contact.picture +
          ')"></div></div><div class="chatname d-inline-block mx-3"><span class="name">' +
          this.contact.fullName +
          '</span><span class="text-muted mx-2">' +
          this.contact.business +
          '</span></div></div>'
        await this.getChat(this.contact)
        this.chat.messages.forEach((element) => {
          if (element.hasOwnProperty('mymessage')) {
            fileHTML += '<div class="row my-1 justify-content-end">'
            fileHTML +=
              '<div class="col-9 text-right border border-success rounded p-0">'
          } else {
            fileHTML += '<div class="row my-1">'
            fileHTML +=
              '<div class="col-9 text-left border border-success rounded p-0">'
          }

          fileHTML += '<h6 class="m-0 p-2">'
          fileHTML += '<span>' + element.date + '</span>'
          fileHTML += '</h6>'
          fileHTML += '<p class="bg-green m-0 p-2">'
          if (typeof element.text === 'object' && element.text != null) {
            // console.log(element.text)
            fileHTML +=
              element.text.full_name + '<a href="' + element.text.url + '">'
            fileHTML +=
              '<i class="fas fa-cloud-download-alt text-success px-2"></i></a>'
          } else {
            // console.log()
            fileHTML += element.text
          }
          fileHTML += '</p>'

          fileHTML += '</div></div>'
        })
        fileHTML += '</div></body></html>'
        // console.log(fileHTML)
        const url = window.URL.createObjectURL(new Blob([fileHTML]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'chat.html') //or any other extension
        document.body.appendChild(link)
        link.click()
      },
    },
  }
</script>

<style scoped>
  .name {
    text-transform: capitalize;
  }
  .number {
    border-radius: 100%;
    padding: 0 5px;
    font-size: 12px;
  }
  .contactcont {
    padding: 20px 10px;
    border-width: 1px 0;
    border-style: solid;
    /* width: 90%; */
  }
  .chatimg,
  .chatname,
  .icons {
    display: inline-block;
    vertical-align: middle;
  }
  .chatimg {
    width: 30%;
  }
  .img {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    margin: 0 auto;
  }
  .chatname {
    width: 50%;
    text-align: left;
  }
  /* .chatname span {
    display: block;
  } */
  .chatname .business {
    font-size: 12px;
  }
  @media screen and (max-width: 1300px) {
    .icons {
      width: 20%;
      margin-left: 0.5rem;
    }
    .chatname {
      width: 40%;
    }
    .chatimg {
      width: 35%;
      text-align: left;
    }
  }
  @media screen and (max-width: 800px) {
    .icons > button {
      display: inline-block;
    }
    .chatimg {
      width: 20%;
      text-align: left;
    }
  }
</style>
