<template>
  <Multiselect
    v-model="modelValue"
    mode="tags"
    :placeholder="title"
    label="name"
    :searchable="false"
    :createTag="false"
    :options="values"
    :class="filter ? 'filtermultiple' : ''"
    @input="$emit('update:modelValue', $event)"
  >
    <template v-slot:tag="{ option, handleTagRemove, disabled }">
      <div class="multiselect-tag is-user">
        <!-- <img :src="require('@/assets/flags/' + option.image)" /> -->
        <span class="flagoption">{{ option.text }}</span>

        <i
          v-if="!disabled"
          @click.prevent
          @mousedown.prevent.stop="handleTagRemove(option, $event)"
        />
      </div>
    </template>
    <template v-slot:option="{ option }">
      <span class="flagoption">{{ option.text }}</span>
      <!-- <img
        class="flagoption"
        :src="require('@/assets/flags/' + option.image)"
      /> -->
    </template>
  </Multiselect>
</template>

<script>
  import Multiselect from '@vueform/multiselect'

  export default {
    name: 'SelectMultiple',
    components: {
      Multiselect,
    },
    props: {
      modelValue: {
        type: Array,
        default: [],
      },
      title: {
        type: String,
        default: '',
      },
      values: {
        type: Object,
        default: { value: '', text: '' },
      },
      filter: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
  }
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
  .multiselect-fake-input {
    display: none;
  }
  .flagoption {
    font-weight: normal;
  }
  .multiselect-caret {
    right: 8px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }
  .multiselect-input {
    text-align: left;
    border-width: 3px;
    border-style: none none solid none;
    border-radius: 0;
    /* width: 60%; */
    margin: 10px auto 0 auto;
    height: 50px;
    background-color: transparent;
  }
  .filtermultiple .multiselect-input {
    margin-top: 0;
    border-width: 0;
  }
  .multiselect-tag img {
    height: 25px;
  }
  .multiselect-tags {
    height: 85%;
    overflow-y: scroll;
    width: 100%;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .multiselect-options {
    margin: 0 auto;
    /* width: 60%; */
    text-align: left;
    scroll-snap-align: center;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .multiselect-option {
    height: 30px;
    display: inline-block;
  }
  .filtermultiple .multiselect-multiple-label,
  .filtermultiple .multiselect-placeholder,
  .filtermultiple .multiselect-single-label {
    font-size: 15px;
  }
  .multiselect-multiple-label,
  .multiselect-placeholder,
  .multiselect-single-label {
    padding-left: 20px;
    font-size: 18px;
  }

  .multiselect-tags::-webkit-scrollbar,
  .multiselect-options::-webkit-scrollbar {
    display: none;
  }
  .read .multiselect-caret {
    display: none;
  }
</style>
