<template>
  <div v-if="data">
    <form action="" v-if="selected == 3">
      <div class="info_color message">
        {{ translations.warning_regist_success }}
      </div>
      <Title :text="translations.title_categories_receive" :align="'middle'" />

      <div v-if="allData.categoriesProd">
        <div
          v-for="(item, index) in allData.categoriesProd"
          :key="index"
          class="iconcont"
        >
          <!-- <div
            class="svgicon"
            :style="`background-image: url(${files + checkImg(item.icon)})`"
          ></div> -->
          <label :for="item.title">
            <input
              type="checkbox"
              :id="item.title"
              :value="item.id"
              v-model="categories"
            />
            {{ item.title }}</label
          >
        </div>
      </div>
    </form>
    <form @submit.prevent="processRegist" class="toolform edit" v-else>
      <div v-if="!edit">
        <div class="menu contrast_bg_color">
          <button
            class="menuitem border_primary"
            :class="checkSelected(item.id)"
            v-for="(item, index) in menu"
            :key="index"
            @click="select(item.id)"
          >
            <i :class="item.icon + ' fa-2x'"></i>
          </button>
        </div>
        <div class="forminputs">
          <div class="contmycard" v-if="selected == 0">
            <Card :data="data" :style="0" :myCard="true" />
          </div>
          <div class="contmycard" v-else-if="selected == 1">
            <VueQRCodeComponent
              text="Hellow!!!"
              class="qr"
              color="#303030"
              :size="180"
            />
          </div>
          <div class="intemcont">
            <label for="regusername">
              <span class="label-style">{{ translations.form_email }}</span>
              <input
                name="regusername"
                type="email"
                disabled="disabled"
                v-model="data.email"
                class="border_category tertiary_color"
            /></label>
          </div>
        </div>
      </div>
      <div class="intemcont" v-if="checkEditOption">
        <label for="regusertype">
          <span class="label-style">{{ translations.form_user_profile }}</span>
          <select
            name="regusertype"
            class="border_category tertiary_color"
            :disabled="!edit"
            v-model="data.option"
            :class="!edit ? 'read' : ''"
          >
            <option
              class="contrast_bg_color"
              v-for="option in limitOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.value }}
            </option>
          </select>
        </label>
      </div>

      <div class="intemcont">
        <label for="regfullname">
          <span class="label-style">{{ translations.form_full_name }}</span>
          <input
            name="regfullname"
            type="text"
            :disabled="!edit"
            v-model="data.fullName"
            class="border_category tertiary_color"
        /></label>
      </div>

      <div class="intemcont">
        <label for="languages">
          <span class="label-style">{{ translations.form_languages }}</span>
          <SelectMultiple
            v-model="data.languages"
            :title="translations.form_ph_languages"
            :values="languages"
            :disabled="!edit"
            name="languages"
            class="selector"
            :class="!edit ? 'read' : ''"
        /></label>
      </div>

      <div v-if="data.option == 1 || data.option == 0" class="intemcont">
        <label for="urldownload" class="logolabel">
          <span class="label-style">{{ translations.form_download }}</span>
          <div class="inputfile border_category tertiary_color">
            <label>
              <input
                type="file"
                :disabled="!edit"
                name="urldownload"
                class="file contrast_color border_category"
                @change="onDownloadChange"
              />
              <span v-if="data.download && !edit">
                <a
                  :href="files + data.download"
                  target="_blank"
                  class="primary_color"
                  >{{ data.download }}</a
                >
              </span>
              <span v-else>
                {{ defineDownload }}
              </span>
              <button
                type="button"
                class="btndelete primary_bg_color contrast_color"
                @click="deleteDownload()"
                v-if="data.download && edit"
              >
                <i class="fas fa-times"></i>
              </button>
            </label>
          </div>
        </label>
      </div>
      <div v-if="checkOption() == 1" class="intemcont">
        <label for="regposition">
          <span class="label-style">{{
            translations.form_business_position
          }}</span>
          <input
            name="regposition"
            type="text"
            :disabled="!edit"
            v-model="data.position"
            class="border_category tertiary_color"
        /></label>
      </div>
      <div class="intemcont">
        <label for="reglogo" class="logolabel"
          ><span class="label-style">{{ translations.form_picture_id }}</span>
          <div
            class="inputfile border_category tertiary_color"
            v-if="edit || !data.picture"
          >
            <label>
              <input
                type="file"
                :disabled="!edit"
                class="file contrast_color border_category"
                @change="onLogoChange"
                v-if="edit"
              />
              {{ translations.form_picture_id }}
            </label>
          </div>
          <div class="imgcont" v-if="edit">
            <div class="preview" v-if="preview">
              <img :src="preview" :alt="data.fullName" />
            </div>
          </div>
          <div v-else-if="data.picture" class="imgcont">
            <div class="preview">
              <img :src="files + data.picture" :alt="data.fullName" />
            </div>
          </div>
        </label>
      </div>
    </form>
    <div class="buttonsform contrast_bg_color">
      <div v-if="!edit && selected != 3">
        <button
          @click="changeEdit()"
          class="btntool primary_bg_color contrast_color"
        >
          {{ translations.btn_edit }}
        </button>
        <button
          @click="removeSelected()"
          class="btntool fourth_bg_color contrast_color"
        >
          {{ translations.btn_close }}
        </button>
      </div>
      <div v-else>
        <button
          @click="changeEdit()"
          class="btntool fourth_bg_color contrast_color"
        >
          {{ translations.btn_cancel }}
        </button>
        <button
          class="btntool primary_bg_color contrast_color"
          @click="editData()"
        >
          {{ translations.btn_save_changes }}
        </button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
  import Card from '@/components/Card.vue'
  import SelectMultiple from '@/components/SelectMultiple.vue'
  import Title from '@/components/Title.vue'
  import VueQRCodeComponent from 'vue-qr-generator'

  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ToolMyAccount',
    data() {
      return {
        edit: false,
        preview: '',
        data: {},
        dataBack: {},
        categories: [],
        show: false,
        menu: [
          {
            id: 0,
            icon: 'far fa-address-card',
            listStyle: 0,
          },
          {
            id: 1,
            icon: 'fas fa-qrcode',
            listStyle: 0,
          },
          {
            id: 3,
            icon: 'far fa-check-square',
            listStyle: 0,
          },
        ],
        selected: null,
        options: [],
        languages: [
          { value: 'en', image: 'en.svg', text: 'English' },
          { value: 'zh', image: 'cn.svg', text: '中文' },
          { value: 'es', image: 'es.svg', text: 'Español' },
          { value: 'it', image: 'it.svg', text: 'Italiano' },
          { value: 'de', image: 'de.svg', text: 'Deutsch' },
          { value: 'fr', image: 'fr.svg', text: 'Français' },
        ],
      }
    },
    components: {
      Card,
      VueQRCodeComponent,
      Title,
      SelectMultiple,
    },
    computed: {
      ...mapState([
        'files',
        'myaccountselected',
        'allData',
        'myCategories',
        'translations',
      ]),
      defineDownload() {
        let result = ''
        if (this.data.download && !this.edit) {
          result = this.files + this.data.download
        } else if (!this.edit) {
          result = '-'
        } else if (this.edit && this.data.download) {
          result = this.translations.label_selected
        } else {
          result = this.translations.label_download
        }
        return result
      },
      defineShowCard() {
        return this.show ? 'btn_hide_card' : 'btn_card'
      },

      checkEditOption() {
        let result = true
        if (
          (this.edit && this.data.option == 0) ||
          this.data.option == 1 ||
          this.data.option == 4 ||
          this.data.option == 6 ||
          this.data.option == 2
        ) {
          result = false
        }
        return result
      },
      limitOptions() {
        let list = this.options
        if (this.edit) {
          list = this.options.filter(
            (option) =>
              option.id != 0 &&
              option.id != 1 &&
              option.id != 2 &&
              option.id != 4 &&
              option.id != 6
          )
        }
        return list
      },
    },
    methods: {
      ...mapActions([
        'editMyInfo',
        'uploadImage',
        'uploadDownload',
        'changeToolSelected',
        'editCategories',
        'getCategories',
      ]),
      deleteDownload() {
        this.data.download = null
      },
      changeEdit() {
        if (this.selected == 3) {
          this.selected = null
        } else {
          this.edit = !this.edit
          if (!this.edit) {
            this.data = { ...this.dataBack }
            this.preview = ''
          }
        }
      },
      select(id) {
        this.selected = id
      },
      checkSelected(id) {
        return this.selected == id
          ? 'contrast_bg_color primary_color'
          : 'contrast_color primary_bg_color'
      },
      removeSelected() {
        this.changeToolSelected(null)
      },
      async editData() {
        if (this.selected == 3) {
          this.editCategories(this.categories)
          if (this.categories.length <= 0) {
            this.receive = false
          }
          this.selected = null
          this.removeSelected()
        } else {
          let dataResult = {
            email: this.data.email,
            code: this.data.code,
            timeOffset: this.data.timeOffset,
            option: this.data.option,
            // business: this.data.business,
            position: this.data.position,
            password: this.data.password,
            fullname: this.data.fullName,
            languages: this.data.languages,
            lang: this.data.lang,
            picture: this.data.picture,
          }
          let array2Sorted = this.dataBack.languages.slice().sort()
          let languagesCheck =
            dataResult.languages.length === this.dataBack.languages.length &&
            dataResult.languages
              .slice()
              .sort()
              .every(function(value, index) {
                return value === array2Sorted[index]
              })

          if (
            dataResult.fullName != this.dataBack.fullName ||
            dataResult.option != this.dataBack.option ||
            dataResult.position != this.dataBack.position ||
            // dataResult.business != this.dataBack.business ||
            !languagesCheck
          ) {
            await this.editMyInfo(dataResult)
          }
          if (typeof this.data.picture !== 'string') {
            this.uploadImage({
              picture: this.data.picture,
              email: this.data.email,
              password: this.data.password,
            })
          }
          if (typeof this.data.download !== 'string') {
            await this.uploadDownload({
              download: this.data.download,
              email: this.data.email,
              password: this.data.password,
            })
          }

          this.edit = false

          this.getLocalStorage()
        }
      },
      getLocalStorage() {
        this.data = JSON.parse(localStorage.getItem('loged'))
        this.data.picture = 'user/' + this.data.picture
        this.dataBack = { ...this.data }
      },
      showCard() {
        this.show = !this.show
      },

      getValue() {
        let value = ''
        switch (this.data.option) {
          case 0:
            value = 'Consumer'
            break
          case 1:
            value = 'Business'

            break
          case 2:
            value = 'Media'

            break
        }
        return value
      },
      onLogoChange(e) {
        const file = e.target.files[0]
        this.data.picture = file

        this.preview = URL.createObjectURL(file)
      },
      onDownloadChange(e) {
        const file = e.target.files[0]
        this.data.download = file
      },
      checkOption() {
        let result = null
        if (
          this.data.option == 1 ||
          this.data.option == 3 ||
          this.data.option == 0
        ) {
          result = 1
        } else if (this.data.option == 2) {
          result = 0
        }

        return result
      },
      checkImg(icon) {
        const regex = /^\S+$/gm
        return regex.exec(icon) ? icon : 'default.svg'
      },

      setCategories() {
        this.categories = this.myCategories
        if (this.categories.length > 0) {
          this.receive = true
        }
      },
      addTranslations() {
        if (this.translations) {
          this.options = [
            { id: 5, value: this.translations.type_user_consumer },
            { id: 2, value: this.translations.type_user_business },
            { id: 3, value: this.translations.type_user_media },
            { id: 1, value: this.translations.type_user_professional },
            { id: 0, value: this.translations.type_user_superuser },
            { id: 6, value: this.translations.type_user_other_professional },
            { id: 4, value: this.translations.type_user_translator },
          ]
        }
      },
    },

    async created() {
      await this.getLocalStorage()
      this.addTranslations()
      if (this.myaccountselected) {
        this.select(3)
      } else {
        await this.getCategories()
        this.setCategories()
      }
    },
  }
</script>

<style scoped>
  .category_btn {
    width: auto;
  }
  .btndelete {
    margin-left: 5px;
    height: auto;
    font-size: 13px;
    border-radius: 40%;
  }
  .content {
    margin: 30px;
  }
  .qr {
    display: inline-block;
  }

  .menu {
    z-index: 10;
    position: fixed;
    right: 0;
    top: 28px;
    left: 0;
  }
  .menu .menuitem {
    width: 45px;
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding: 5px;
    font-size: 15px;
  }
  .menu .selected {
    background-color: #fff;
  }
  .menu::-webkit-scrollbar {
    display: none;
  }
  .menu .itemmenu {
    width: 45px;
    border-width: 1px;
    border-style: solid;
    padding: 5px;
    font-size: 15px;
  }
  .toolform .inputfile {
    margin: 10px;
  }
  .inputfile {
    vertical-align: center;
  }
  .selectedTitle {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-transform: uppercase;
  }
  .edit {
    max-height: 600px !important;
    margin-top: 60px;
  }
  .contmycard {
    margin: 75px 0 0 0;
  }
  label,
  .selector {
    display: inline-block;
    vertical-align: middle;
  }
  label > span.label-style {
    width: 30%;
    display: inline-block;
    margin-left: 3rem;
    vertical-align: top;
  }
  label {
    width: 100%;
  }
  .iconcont {
    display: block;
    width: 25%;
    text-align: center;
    margin: 0 auto;
  }

  .svgicon {
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    height: 100px;
  }

  .selector {
    width: 60%;
    margin: 0 10px 10px 10px;
  }
  .logolabel {
    vertical-align: top;
  }
  .imgcont {
    width: 60%;
    display: inline-block;
    margin: 10px;
  }
  .preview {
    /* border: 1px solid #303030; */
    padding: 5px;
    position: relative;
    height: 150px;
    width: 150px;
    margin: 0 auto;
  }

  .preview img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 20px auto;
  }

  .intemcont,
  label {
    height: auto;
  }
  label {
    vertical-align: middle;
  }
  .read {
    appearance: none;
  }
  .message {
    font-size: 20px;
    width: 50%;
    margin: 20px auto;
  }
  @media screen and (max-width: 1000px) {
    .toolform input,
    .toolform label,
    .toolform select,
    .toolform .selector,
    .toolform .inputfile {
      width: 90%;
    }
    .iconcont {
      width: 50%;
    }
    .message {
      width: 70%;
    }
  }
  @media screen and (max-width: 800px) {
    .iconcont {
      width: 90%;
    }
    .message {
      width: 80%;
    }
  }
  @media screen and (max-width: 760px) {
    .contmycard {
      margin: 0;
    }
    .message {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 500px) {
    .message {
      width: 90%;
    }
  }
</style>
