<template>
  <div>
    <div class="briefcasecont">
      <div class="menu contrast_bg_color">
        <button
          class="menuitem border_primary"
          :class="checkSelected(item.id)"
          v-for="(item, index) in menu"
          :key="index"
          @click="select(item.id)"
        >
          <i :class="item.icon + ' fa-2x'"></i>
        </button>
        <h3 class="selectedTitle border_primary">
          {{ defineTitle }}
        </h3>
      </div>
      <div v-if="selected == 0" class="searchlist bookmarkscont">
        <div class="favoritecont">
          <div v-if="myFavoritesData.length > 0">
            <SearchList :data="myFavoritesData" :type="'favorite'" />
          </div>
          <div v-else class="message error_color">
            <h2>
              {{ translations.title_no_bookmarks }}
            </h2>
            <p>{{ translations.warning_no_bookmarks }}</p>
          </div>
        </div>
      </div>
      <div v-else-if="selected == 1" class="searchlist">
        <div class="cardcont" v-if="cardsData.length > 0">
          <SearchList :data="cardsData" :type="'card'" />
        </div>
        <div class="message error_color" v-else>
          <h2>
            {{ translations.title_no_cards }}
          </h2>
          <p>
            {{ translations.warning_no_cards }}
          </p>
        </div>
      </div>
      <div v-else-if="selected == 2" class="searchlist">
        <div class="email">
          <p>
            {{ translations.warning_confirm_send_email }}
          </p>
          <button
            class="btn primary_bg_color contrast_color"
            @click="generateZip()"
          >
            {{ translations.btn_confirm }}
          </button>
        </div>
      </div>
    </div>
    <div class="buttonsform contrast_bg_color">
      <button
        @click="removeSelected()"
        class="btntool fourth_bg_color contrast_color"
      >
        {{ translations.btn_close }}
      </button>
    </div>
  </div>
</template>

<script>
  import ListStyle from '@/components/ListStyle.vue'
  import Card from '@/components/Card.vue'
  import SearchList from '@/components/SearchList.vue'
  import Calendar from '@/components/Calendar.vue'
  import router from '../router'

  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'ToolMyBriefcase',
    components: {
      ListStyle,
      Card,
      SearchList,
      Calendar,
    },
    data() {
      return {
        event: {},
        menu: [
          {
            id: 0,
            icon: 'far fa-bookmark',
            listStyle: 0,
          },
          {
            id: 1,
            icon: 'far fa-address-card',
            listStyle: 0,
          },
          {
            id: 2,
            icon: 'far fa-envelope',
            listStyle: 0,
          },
        ],
        selected: 0,
        type: 'week',
      }
    },
    computed: {
      ...mapState([
        'myFavoritesData',
        'cardsData',
        'files',
        'myCalendar',
        'tools',
        'translations',
      ]),
      defineTitle() {
        let title = ''
        switch (this.selected) {
          case 1:
            title = this.translations.title_briefcase_cards
            break
          case 0:
            title = this.translations.title_briefcase_favorites
            break
          case 2:
            title = this.translations.title_send_to_email
            break
          case 3:
            title = this.translations.title_briefcase_calendar
            break
        }
        return title
      },
    },
    methods: {
      ...mapActions([
        'getFavorites',
        'getCards',
        'getMyCalendar',
        'changeToolSelected',
      ]),
      select(id) {
        this.selected = id
      },
      checkSelected(id) {
        return this.selected == id
          ? 'contrast_bg_color primary_color'
          : 'contrast_color primary_bg_color'
      },
      changeStyleSelected(id) {
        this.menu[this.selected].listStyle = id
      },
      defineType() {
        return (this.type = this.type == 'week' ? 'day' : 'week')
      },
      addEvent(event) {
        this.event = event
        router.push('/call/' + event.id)
        this.changeToolSelected(null)
      },
      generateZip() {
        console.log('Here we will generate a zip')
      },
      removeSelected() {
        this.changeToolSelected(null)
      },
    },

    created() {
      this.getFavorites()
      this.getCards()
      this.getMyCalendar()
    },
  }
</script>

<style scoped>
  .menu {
    z-index: 10;
    position: fixed;
    right: 0;
    top: 28px;
    left: 0;
  }

  .briefcasecont {
    padding: 0 10px 10px 10px;
    position: relative;
  }

  .email button {
    width: 16%;
    font-size: 14px;
  }
  .searchlist {
    margin-top: 130px;
  }
  .menu .menuitem {
    width: 45px;
    border-width: 1px;
    border-style: solid;
    margin-top: 10px;
    padding: 5px;
    font-size: 15px;
  }
  .menu .selected {
    background-color: #fff;
  }
  .selectedTitle {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    text-transform: uppercase;
  }
  .favorite img {
    width: 100px;
  }
  /* .favoritecont {
    max-height: 912px;
    overflow-y: scroll;
  } */
</style>
